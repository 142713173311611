import set from 'lodash.set'
import unset from 'lodash.unset'

interface Removed {
  type: 'remove'
  path: string
}

interface Update {
  type: 'update'
  path: string
  value: any
}

interface Set {
  value: any
  type: 'set'
}

interface Reset {
  value: any
  type: 'reset'
}

interface DefAction {
  value: any
  path: string
  type?: undefined
}

export type IAction = Removed | Update | Set | Reset | DefAction

export interface IOption {
  key: string
  text: string
}

export const commonReducer = (state: any, action: IAction) => {
  switch (action.type) {
    case 'remove': {
      const removedState = { ...state }
      if (action.path) unset(removedState, action.path)
      return removedState
    }
    case 'update':
      return set({ ...state }, (action.path || ''), action.value)
    case 'set': {
      const newState = { ...state, ...action.value }
      return newState
    }
    case 'reset':
      return action.value
    default: {
      const newState = { ...state }
      return set(newState, (action.path || ''), action.value)
    }
  }
}
