import { FormEvent, useCallback, useReducer } from 'react'
import { FWTextField, PrimaryButton, Stack } from '../Common/Common'
import { commonReducer } from '../Common'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input/input'
import { E164Number } from 'libphonenumber-js/types'
import { Label, Spinner } from '@fluentui/react'
import { ErrorMessage, ErrorCard } from '../Common/Error'
import { states } from '../util/states'
import { Select } from '../Common/Select'

const initialState = {
  fullName: '',
  phoneNumber: ''
}

const initialOptions = {
  errors: {},
  loading: false,
  success: false,
  error: false
}

function Success () {
  return <main className='grid px-8 sm:px-16 h-screen p-0 items-center slide-in'>
    <Stack horizontalAlign='center' spacing={24}>
      <h1 className='text-4xl text-center tracking-tight text-blue-900 sm:text-5xl relative z-10 font-medium' style={{ width: 300 }}>
        <svg style={{ top: 50 }} aria-hidden="true" viewBox="0 0 418 42" className="absolute left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
        <span className="relative">Success</span>
      </h1>
      <p className='text-2xl text-center font-light'>
        We&apos;ve received your form submission. Thank you for reaching out! We&apos;ll process your information and be in touch if necessary. In the mean time you can visit our job board.
      </p>
      <a href='https://mleehealthcare.com'
        target='_blank'
        rel="noreferrer"
        className='bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
      >MLee Healthcare</a>
    </Stack>
  </main >
}

const validate = (state:any) => {
  const errors: any = {}

  if (!isPossiblePhoneNumber(state.phoneNumber)) {
    errors.phoneNumber = 'Please enter valid phone number'
  }

  if (!state.states?.length) {
    errors.states = 'This field is required'
  }

  return errors
}

export function BecomePartner () {
  const [{ errors, loading, success, error }, optionsReducer] = useReducer(commonReducer, initialOptions)
  const [state, reducer] = useReducer(commonReducer, initialState)

  const handleChange = useCallback((path: string) => (ev: any, value: string | undefined) => {
    reducer({ path, value })
  }, [])

  const handlePhoneChange = useCallback((value: E164Number | undefined) => {
    if (errors.phoneNumber) optionsReducer({ path: 'errors.phoneNumber', value: '' })
    reducer({ path: 'phoneNumber', value })
  }, [errors.phoneNumber])

  const handleMultiselect = useCallback((value: unknown) => {
    if (errors.states) optionsReducer({ path: 'errors.states', value: '' })
    if (Array.isArray(value)) {
      reducer({ path: 'states', value: value.map(({ value }) => value).join(', ') })
    }
  }, [errors.states])

  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const errors = validate(state)

    if (Object.values(errors).length) return optionsReducer({ path: 'errors', value: errors })

    optionsReducer({ path: 'loading', value: true })
    try {
      const res = await fetch(process.env.REACT_APP_API_BASE_URL + '/partner', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...state })
      })

      if (res.status === 200) {
        optionsReducer({
          type: 'set',
          value: {
            loading: false,
            success: true
          }
        })
        reducer({ type: 'reset', value: initialState })
      } else {
        console.log(res)
        optionsReducer({ type: 'set', value: { loading: false, error: true } })
      }
    } catch (e) {
      console.log(e)
      optionsReducer({ type: 'set', value: { loading: false, error: true } })
    }
  }, [state])

  const usstates = states.arrayOf('names')

  return success
    ? <Success />
    : <main className='grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-16 h-screen sm:p-0 sm:px-16 px-8 items-center relative'>
      <div>
        <p className='text-center m-0 text-blue-900 font-medium'>For Healthcare Recruiters</p>
        <h1 className='text-4xl text-center tracking-tight text-blue-900 sm:text-5xl relative z-10 mb-8 mt-0'>
          <svg style={{ bottom: -14 }} aria-hidden="true" viewBox="0 0 418 42" className="absolute left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
          <span className="relative font-medium">Interested in becoming a partner?</span>
        </h1>
        <p className='text-center'>
          Welcome to MLee Healthcare&apos;s Vendor Management
          Solution. Unlock the future of healthcare recruitment efficiency. Our VMS is tailored to empower our esteemed partners and clients with a streamlined vendor management experience, optimizing processes and fostering collaboration.
        </p>
      </div>
      <div>
        {error && <ErrorCard error text='Please make sure all the required fields are filled out and submit the form again.' />}
        <form onSubmit={handleSubmit}>
          <FWTextField required label="Full Name" onChange={handleChange('fullName')} />
          <FWTextField required label="Orgazition Name" onChange={handleChange('organization')} />
          <FWTextField required label="Job Title" onChange={handleChange('jobTitle')} />
          <FWTextField required label="Email" onChange={handleChange('email')} type='email' />
          <Stack>
            <Stack className={errors.phoneNumber ? 'phone-input hasError' : 'phone-input'} styles={{ root: { width: '100%' } }}>
              <Label required>Phone Number</Label>
              <PhoneInput required defaultCountry='US' onChange={handlePhoneChange} value={state.phoneNumber} />
              {errors.phoneNumber && <ErrorMessage>{errors.phoneNumber}</ErrorMessage>}
            </Stack>
          </Stack>
          <Stack>
            <Label required>States worked in</Label>
            <Select
              isMulti
              placeholder=''
              options={usstates.map(s => ({ value: s, label: s }))}
              onChange={handleMultiselect}
              error={errors.states}
            />
            {errors.states && <ErrorMessage>{errors.states}</ErrorMessage>}
          </Stack>
          <FWTextField
            multiline
            label='Comments'
            placeholder='Tell us about yourself. Years of experience, top 3 specialties, etc.'
            onChange={handleChange('comments')}
            />
          <PrimaryButton
            type='submit'
            className='mt-8 sm:mb-0 mb-8'
            onRenderText={() => loading ? <Spinner key='spinner' className='fade-in' /> : <span key='submit'>Submit</span>} />
        </form>
      </div>
    </main>
}
