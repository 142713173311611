import { useLoaderData, useRevalidator } from 'react-router-dom'
import { Header } from '../Common/Header'
import { APIUser, OrganizationType } from '../util/commonTypes'
import { PrimaryButton, Stack } from '../Common/Common'
import { ContractIcon, EmailIcon, ExportIcon, FilledAlertIcon, InfoIcon, PhoneIcon, UserNoOutlineIcon } from '../assets/Icons'
import { theme } from '../util/theme'
import { DefaultButton, Panel, PanelType, TooltipHost } from '@fluentui/react'
import { formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import { UsersTable } from './UsersTable'
import { EmptyState, NotFound } from '../NotFound/NotFound'
import { EditUserView } from './EditUserView'
import { useCallback, useReducer } from 'react'
import { commonReducer } from '../Common'
import { api } from '../util/api'
import { CREATE_USER, DELETE_USER, UPDATE_USER } from '../queries/Users'
import { ErrorCard, NonFatalError } from '../Common/Error'
import { WarningModal } from '../Common/WarningModal'
import { Toast } from '../Toast/Toast'
import { Files } from './Files'

const initialOptions = {
  open: false,
  edit: false,
  errors: {},
  apiErrors: [],
  warningModal: false,
  isFilesOpen: false
}

const validateUser = (user: Partial<APIUser>) => {
  const errors: Partial<Record<keyof APIUser, string>> = {}

  if (!user.fullName) errors.fullName = 'Full Name is required'
  if (user.phoneNumber && !isPossiblePhoneNumber(user.phoneNumber)) errors.phoneNumber = 'Phone number not valid'
  if (!user.email) errors.email = 'Email is required'

  return errors
}

const documentTypes = {
  [OrganizationType.CLIENT]: ['Contract', 'Contractor Handbook'],
  [OrganizationType.PARTNER]: ['W-9', 'Certificate of insurance'],
  [OrganizationType.INTERNAL]: ['W-9', 'Certificate of insurance']
}

export function Profile () {
  const revalidator = useRevalidator()
  const { user: s, users: org, ...rest } = useLoaderData() as { users: APIUser[], user: APIUser[], errors?: string[] }

  const [user, userReducer] = useReducer(commonReducer, {})
  const [{ open, edit, errors, apiErrors, warningModal, user: deleteUser, isFilesOpen }, optionsReducer] = useReducer(commonReducer, initialOptions)

  const togglePanel = useCallback(() => {
    optionsReducer({ path: 'open', value: !open })
  }, [open])

  const toggleFilesPanel = useCallback(() => {
    optionsReducer({ path: 'isFilesOpen', value: !isFilesOpen })
  }, [isFilesOpen])

  const toggleWarning = useCallback((user?: APIUser) => {
    if (user?.fullName) optionsReducer({ type: 'set', value: { user, warningModal: !warningModal } })
    else optionsReducer({ path: 'warningModal', value: !warningModal })
  }, [warningModal])

  const handleChange = useCallback((path: string) => (ev: any, value: any) => {
    if (errors[path]) optionsReducer({ type: 'remove', path: `errors[${path}]` })
    userReducer({ path, value })
  }, [errors])

  const dismiss = useCallback(() => {
    optionsReducer({ type: 'reset', value: initialOptions })
    userReducer({ type: 'reset', value: {} })
  }, [])

  const handleEdit = useCallback((user: APIUser) => {
    console.log(user)
    userReducer({
      type: 'reset',
      value: {
        id: user.id,
        fullName: user.fullName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        userName: user.userName,
        admin: user.admin,
        orgAdmin: user.orgAdmin,
        contractSigner: user.contractSigner
      }
    })
    optionsReducer({ type: 'set', value: { edit: true, open: true } })
  }, [])

  const handleSubmit = useCallback(async () => {
    const errors = validateUser(user)
    if (Object.values(errors).length) return optionsReducer({ path: 'errors', value: errors })

    const newUser = Object.assign({}, user)
    const query = edit ? UPDATE_USER : CREATE_USER
    const args = { args: newUser } as any

    if (edit) {
      args.id = newUser.id
      delete newUser.id
    }

    console.log(args)
    try {
      const res = await api.query(query, args)
      const success = res.createUser?.success || res.updateUser?.success
      const messages = res.errors ?? res.updateUser?.messages?.map(({ message }: { message: string }) => message) as string[]

      if (success) {
        dismiss()
        revalidator.revalidate()
      } else if (messages) {
        return optionsReducer({ path: 'apiErrors', value: messages })
      }
    } catch (e: any) {
      console.log(e?.message)
    }
  }, [user, dismiss, revalidator, edit])

  const handleDelete = useCallback(async () => {
    if (!deleteUser?.id) return
    const res = await api.query(DELETE_USER, { id: deleteUser.id })

    if (res?.deleteUser?.success) {
      dismiss()
      revalidator.revalidate()
    } else if (res.errors) {
      console.log(res)
      res.errors.map((m: any) => Toast.makeText({ message: m, type: 'alert', duration: 3000 }))
    }
  }, [dismiss, revalidator, deleteUser?.id])

  const onRenderFooter = useCallback(() => {
    return <Stack horizontal spacing={16} horizontalAlign='end'>
      <DefaultButton text='Cancel' onClick={dismiss} style={{ backgroundColor: 'white' }} />
      <PrimaryButton text='Submit' onClick={handleSubmit} />
    </Stack>
  }, [dismiss, handleSubmit])

  const onRenderFileFooter = useCallback(() => {
    return <Stack horizontal spacing={16} horizontalAlign='end'>
      <DefaultButton text='Cancel' onClick={dismiss} style={{ backgroundColor: 'white' }} />
      <PrimaryButton text='Submit' onClick={handleSubmit} />
    </Stack>
  }, [dismiss, handleSubmit])

  if (rest.errors) return <NonFatalError errors={rest.errors} />
  if (!s?.length) return <NotFound />
  const self = s[0] as APIUser

  return <main>
    <Header>
      <Stack>
        <h1 className='text-4xl text-center m-0'>{self.fullName}</h1>
        {self.contractSigner && <Stack horizontal verticalAlign='center'>
          <ContractIcon size={30} color={theme.blue.hex()} />
          <span>Contract Signer</span>
        </Stack>}
      </Stack>
      <Stack horizontalAlign='center'>
        <span className='text-blue-900 text-sm'>Organization:</span>
        <Stack horizontal verticalAlign='center' spacing={8}>
          <h2>{self.organization.name}</h2>
          <TooltipHost
            id='more-info'
            content={self.organization.description}
            calloutProps={{ isBeakVisible: false }}
            styles={{ root: { display: 'flex' } }}
          >
            <InfoIcon size={18} color={theme.blue.hex()} />
          </TooltipHost>
        </Stack>
      </Stack>
    </Header>
    <Stack className='bg-gray-100 p-8 pt-0' wrap={true} horizontal horizontalAlign='space-evenly' styles={{ inner: { gap: 15 } }}>
      <Stack horizontal spacing={6} verticalAlign='center'>
        <UserNoOutlineIcon color={theme.blue.hex()} size={20} strokeWidth={1.5} />
        <span>{self.userName}</span>
      </Stack>
      <Stack horizontal spacing={6} verticalAlign='center'>
        <EmailIcon color={theme.blue.hex()} size={20} />
        <span>{self.email}</span>
      </Stack>
      {self.phoneNumber && <Stack horizontal spacing={6} verticalAlign='center'>
        <PhoneIcon size={20} color={theme.blue.hex()} />
        <span>{formatPhoneNumber(self.phoneNumber)}</span>
      </Stack>}
    </Stack>

    <div
      // wrap
      // horizontal
      // verticalAlign='center'
      // horizontalAlign='space-between'
      className='bg-gray-100 p-4 my-8 grid sm:flex justify-evenly sm:justify-between grid-cols-1 sm:grid-cols-5 gap-3'
      // styles={{ inner: { gap: 15 } }}
    >
      <h2 className='text-left'>Documents:</h2>
      <Stack horizontal horizontalAlign='space-evenly' className='gap-10 sm:gap-20'>
      {documentTypes[self.organization.type].map(d => <Stack key={d} horizontal spacing={8} verticalAlign='center'>
        <FilledAlertIcon size={16} />
        <span>{d}</span>
      </Stack>)}
      </Stack>

      <button className='flex items-center gap-1 justify-center' onClick={toggleFilesPanel}>
        <ExportIcon size={18} />
        Upload Documents
      </button>
    </div>

    {org?.length
      ? <>
        <Stack horizontal horizontalAlign='space-between' className='p-4 bg-gray-100'>
          <h2>Organization Users</h2>
          {(self.admin || self.orgAdmin) && <PrimaryButton text='Add User' onClick={togglePanel} />}
        </Stack>
        <UsersTable currentUser={self} data={org} handleEdit={handleEdit} handleDelete={toggleWarning} />
      </>
      : <EmptyState text='No Users'><PrimaryButton text='Add User' onClick={togglePanel} /></EmptyState>}
    <Panel
      isLightDismiss
      isFooterAtBottom
      headerText={`${edit ? 'Edit' : 'Add'} User`}
      isOpen={open}
      onDismiss={dismiss}
      type={PanelType.smallFixedFar}
      onRenderFooterContent={onRenderFooter}
      styles={{
        content: { padding: 0 }
      }}
    >
      <EditUserView currentUser={self} user={user} handleChange={handleChange} errors={errors} edit={edit} />
      <Stack className='px-4' spacing={8}>
        {apiErrors.map((e: string, key: string) => <ErrorCard key={key} text={e} />)}
      </Stack>
    </Panel>
    <WarningModal
      isOpen={warningModal}
      closeModal={toggleWarning}
      title={`Are you sure you want to delete ${deleteUser?.fullName ?? ''}?`}
      handleConfirm={handleDelete}
    />
    <Panel
      isLightDismiss
      isFooterAtBottom
      headerText='Files'
      isOpen={isFilesOpen}
      onDismiss={dismiss}
      type={PanelType.smallFixedFar}
      onRenderFooterContent={onRenderFileFooter}
      styles={{
        content: { padding: 0 }
      }}
    >
      <Files types={documentTypes[self.organization.type]} />
    </Panel>
  </main>
}
