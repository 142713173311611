import React, { useContext } from 'react'
import styled from 'styled-components'
import { Table, Header, HeaderCell, Body, Row, RowCell } from '../Common/Table'
import { APICandidate, OrganizationType } from '../util/commonTypes'
import { EditIcon, EmailIcon, PhoneIcon, WorldIcon } from '../assets/Icons'
import { EmptyState } from '../NotFound/NotFound'
import { OrgContext } from '../Nav/OrgContext'
import { useWindowSize } from '../hooks/resize'
import { CardWrapper, Stack } from '../Common/Common'
import { theme } from '../util/theme'
import { pluralize } from '../util/helpers'
import { Link } from 'react-router-dom'

interface ICandidatesTable {
  data: APICandidate[]
  handleClick: (value: APICandidate) => void
  handleEdit: (value: APICandidate) => void
}

interface ICard {
  data: APICandidate[]
  handleClick: (row: APICandidate) => void
  handleEdit: (row: APICandidate) => void
  canEdit?: boolean
}

const StyledRowCell = styled(RowCell)`
  padding: 10px 0;
`

const EditButton = styled.button``

function Cards ({ data, handleClick, handleEdit, canEdit }: ICard) {
  return <Stack spacing={16} className='mt-4'>
    {data?.map((row, k) => <CardWrapper key={k}>
      <Stack spacing={8}>
        <Stack horizontal horizontalAlign='center' verticalAlign='center' spacing={8}>
          <Link to={`/candidates/${row.id}`} className='text-blue-950'>
            <h2 className='underline'>{row.firstName} {row.lastName}</h2>
          </Link>
          {canEdit && <EditButton onClick={() => handleEdit(row)}>
            <EditIcon color={theme.blue.hex()} size={20} />
          </EditButton>}
        </Stack>
        <Stack wrap horizontal spacing={8}>
          <span><b>Job{pluralize(row.jobs)}:</b> {row.jobs.join(', ')}</span>
          <span><b>{pluralize(row.specialties, ['Specialties', 'Specialty'])}:</b> {row.specialties?.length ? row.specialties.join(', ') : 'N/A'}</span>
        </Stack>
        <Stack horizontal horizontalAlign='space-between'>
          <Stack horizontal spacing={16} verticalAlign='center'>
            <a href={`tel:${row.mobileNumber}`}><PhoneIcon size={20} color={theme.blue.hex()} /></a>
            <a href={`mailto:${row.email}`}><EmailIcon size={20} color={theme.blue.hex()} /></a>
          </Stack>
          <Stack horizontal spacing={4}>
            <WorldIcon size={20} color={theme.blue.hex()} /><span>{row.address?.city}, {row.address?.state}</span>
          </Stack>
        </Stack>
      </Stack>
    </CardWrapper>)}
  </Stack>
}

export const CandidatesTable: React.FC<ICandidatesTable> = ({ data, handleClick, handleEdit }) => {
  const orgType = useContext(OrgContext)
  const [width] = useWindowSize()

  const canEdit = orgType !== OrganizationType.CLIENT

  if (!data.length) return <EmptyState text='No Candidates Available' />

  if (width < 700) return <Cards data={data} handleClick={handleClick} handleEdit={handleEdit} canEdit={canEdit} />

  return <Table id='requisitions-table' classNames={{ table: 'table' }}>
    <Header>
      <HeaderCell>Name</HeaderCell>
      <HeaderCell>DOB</HeaderCell>
      <HeaderCell>Discipline</HeaderCell>
      <HeaderCell>Job Title</HeaderCell>
      <HeaderCell>Specialty</HeaderCell>
      {orgType !== OrganizationType.CLIENT && <HeaderCell>Edit</HeaderCell>}
    </Header>

    <Body>
      {data.map((row, key) => {
        return <Row key={key}>
          <StyledRowCell>{row.firstName} {row.lastName}</StyledRowCell>
          <RowCell onClick={() => handleClick(row)}>{row.dob}</RowCell>
          <RowCell onClick={() => handleClick(row)}>{row.discipline}</RowCell>
          <RowCell onClick={() => handleClick(row)}>{row.jobs?.join(', ')}</RowCell>
          <RowCell onClick={() => handleClick(row)}>{row.specialties?.length ? row.specialties.join(', ') : 'N/A'}</RowCell>
          {canEdit && <RowCell style={{ maxWidth: 20 }}>
            <EditButton onClick={() => handleEdit(row)}>
              <EditIcon size={20} />
            </EditButton>
          </RowCell>}
        </Row>
      })}
    </Body>
  </Table>
}
