import unset from 'lodash.unset'
import set from 'lodash.set'

export interface IAction {
  type?: 'remove' | 'update' | 'set' | 'reset';
  path?: string;
  value?: any;
}

export const commonReducer = (state:any, action:IAction) => {
  switch (action.type) {
    case 'remove': {
      const removedState = { ...state }
      if (action.path) unset(removedState, action.path)
      return removedState
    }
    case 'update':
      return set({ ...state }, (action.path || ''), action.value)
    case 'set': {
      const newState = { ...state, ...action.value }
      return newState
    }
    case 'reset':
      return action.value
    default: {
      const newState = { ...state }
      return set(newState, (action.path || ''), action.value)
    }
  }
}
