export const requisitionData = [
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'open',
    billRate: 85
  },
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'open',
    billRate: 85
  },
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'open',
    billRate: 85
  },
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'inprogress',
    billRate: 85
  },
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'inprogress',
    billRate: 85
  },
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'completed',
    billRate: 85
  },
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'completed',
    billRate: 85
  },
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'completed',
    billRate: 85
  },
  {
    facility: 'Ambulatory, Rochester, New York',
    scheduleType: 'Deferred Schedule',
    specialtyDept: 'RGH-GI WILSON CENTER 103208012',
    shiftType: '8hr Days',
    hours: '',
    startDate: '10/23/2023',
    typeForAllied: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    typeForNursing: 'Daily OT after 24 hours [Based on multiplier] Weekly OT after 40 hours [Based on multiplier]',
    endDate: '01/27/2024',
    onCall: 'No',
    type: 'Travel',
    coordinator: 'Fabiya Sankar',
    description:
      'LPN $55 or End Tech $55 Wilson GI- 800 Carter St Rochester NY Pittsford GI, and Brockport GI (as needed) M-F start time varies between 6:30a-8a ending between 3p-4:30p Endo tech experience and high level disinfection GI Endoscopy- GI Endo tech and high level disinfection',
    requisition: 'Ambulatory-AN-0988',
    jobTitle: 'Endoscopy Technician LPN / LVN',
    pricingNegotiations: 'Yes',
    weeks: '13',
    status: 'open',
    billRate: 85
  }
]
