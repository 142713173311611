export const GET_REQUISITIONS = `
  query GetRequisitions($filters: RequisitionFilters) {
    requisitions(filters: $filters){
      id
      startDate
      endDate
      billRate
      status
      guaranteedHours
      onCall
      shiftType
      discipline_id
      job_id
      organization_id
      comments
      job
      discipline
      city
      state
      specialty
      candidates {
        id
        name
        email
        mobileNumber
        accepted
      }
    }
    disciplines {
      id
      discipline
    }
  }
`

export const CREATE_REQUISITION = `
  mutation CreateRequisition($args: RequisitionInput!) {
    createRequisition(args: $args) {
      messages {
        arg
        message
      }
      success
    }
  }
`

export const DELETE_REQUISITION = `
  mutation DeleteRequisition($id: String!) {
    deleteRequisition(id: $id) {
      success
      messages {
        message
      }
    }
  }
`

export const CLOSE_REQUISITION = `
  mutation CloseRequisition($id: String!) {
    closeRequisition(id: $id) {
      success
      messages {
        message
      }
    }
  }
`

export const SUBMIT_CANDIDATE_REQUISITION = `
  mutation SubmitCandidate($candidateId: String!, $requisitionId: String!) {
    submitCandidate(candidateId: $candidateId, requisitionId: $requisitionId) {
      messages {
        arg
        message
      }
      success
    }
  }
`

export const GET_JOBS = `
  query GetJobs($id: Float!) {
    jobs(id: $id) {
      id
      job
      specialty
      extra
    }
  }
`

export const ACCEPT_CANDIDATE = `
  mutation AcceptCandidate($candidateId: String!, $requisitionId: String!) {
    acceptCandidate(candidateId: $candidateId, requisitionId: $requisitionId) {
      success
      requisition {
        id
        startDate
        endDate
        billRate
        status
        guaranteedHours
        onCall
        shiftType
        discipline_id
        job_id
        organization_id
        comments
        job
        discipline
        city
        state
        specialty
        candidates {
          id
          name
          email
          mobileNumber
          accepted
        }
      }
    }
  }
`
