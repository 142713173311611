import { useCallback, useMemo, useReducer } from 'react'
import { Drop, Stack } from '../Common/Common'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { commonReducer } from '../Common'
import { Dropdown, Label } from '@fluentui/react'
import { ErrorMessage } from '../Common/Error'

export function Files ({ types }: { types: string[] }) {
  const [{ errors }, dispatch] = useReducer(commonReducer, { errors: {} })

  const handleChange = useCallback((path: string) => (ev: any, value: any) => {
    dispatch({ path, value })
  }, [])

  const handleDateChange = useCallback((ev: any) => {
    handleChange('expiration')(ev, ev.target.value)
  }, [handleChange])

  const onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (!acceptedFiles.length || acceptedFiles.length > 1 || rejectedFiles.length) return

    const file = acceptedFiles[0]
    handleChange('resume')(undefined, file)
  }, [handleChange])

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, isDragReject, fileRejections } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
      'application/pdf': ['.pdf']
    }
  })

  const fileNames = useMemo(() => acceptedFiles.map((file) => file.name), [acceptedFiles])

  return <Stack className='px-2 py-8' spacing={24}>
    <Dropdown
      required
      label='Document Type'
      options={[...types.map(t => ({ key: t, text: t })), { key: 'other', text: 'Other' }]}
    />
    <Drop {...getRootProps()} $error={!!errors.file || isDragReject || fileNames.length > 1 || !!fileRejections.length}>
      <input {...getInputProps()} />
      {
        fileRejections.length
          ? <p>File type not accepted</p>
          : fileNames.length > 1
            ? <p>Only 1 file is allowed</p>
            : fileNames.length
              ? <p>{fileNames.join(', ')}</p>
              : isDragActive
                ? <p>Drop the file here ...</p>
                : <p>Drag and drop file here or click to select file<br />(Only 1 file accepted)<br />.pdf .jpeg .jpg .png</p>
      }
    </Drop>
    <Stack styles={{ root: { width: '100%' } }}>
      <Label required htmlFor='expiration'>Expiration</Label>
      <input className={errors.expiration ? 'hasError' : ''} type="date" id="expiration" name="expiration" onChange={handleDateChange} />
      {errors.startDate && <ErrorMessage>{errors.startDate}</ErrorMessage>}
    </Stack>
  </Stack>
}
