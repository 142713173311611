import React from 'react'
import styled from 'styled-components'
import { Stack } from './Common'

interface IHeader {
  children?: React.ReactNode
}

const StyledHeader = styled.header`
`

export function Header ({ children }:IHeader) {
  return <StyledHeader className='p-4 bg-gray-100 mt-0'>
    <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
      {children}
    </Stack>
  </StyledHeader>
}
