import React, { useCallback, useMemo, useState } from 'react'
import { Dropdown, Label } from '@fluentui/react'
import { FWTextField, Stack } from '../Common/Common'
import { APIDiscipline, IRequisition } from '../util/commonTypes'
import { ErrorMessage } from '../Common/Error'
import { Select } from '../Common/Select'
import { api } from '../util/api'
import { GET_JOBS } from '../queries/Requisitions'

interface IAddRequisitionForm {
  state: IRequisition
  handleChange: (path: keyof IRequisition) => (event: any, value: any) => void
  errors?: { [key: string]: string }
  disciplines: APIDiscipline[]
}

export const AddRequisitionForm: React.FC<IAddRequisitionForm> = ({ state, handleChange, errors = {}, disciplines }) => {
  const [jobs, setJobs] = useState([] as any[])

  const fetchJobs = useCallback(async (id: number) => {
    const { jobs } = await api.query(GET_JOBS, { id })
    setJobs(jobs)
  }, [])

  const handleDiscipline = useCallback(async (newValue: { value: string, label: string, id: number }) => {
    await fetchJobs(newValue.id)
    if (state.job) {
      handleChange('job')(null, null)
      handleChange('specialty')(null, null)
    }
    handleChange('discipline')(null, newValue)
  }, [fetchJobs, handleChange, state.job])

  const handleSelect = useCallback((path: keyof IRequisition) => (newValue: any) => {
    handleChange(path)(null, newValue)
  }, [handleChange])

  const handleDateChange = useCallback((path: keyof IRequisition) => (ev: any) => {
    handleChange(path)(ev, ev.target.value)
  }, [handleChange])

  const handleDropdown = useCallback((path: keyof IRequisition) => (ev: any, value: any) => {
    handleChange(path)(ev, value.key)
  }, [handleChange])

  const specialties = useMemo(() => jobs?.filter(j => state.job?.value === j.job && j.specialty).map(s => ({ value: s.specialty, label: s.specialty, id: s.id, specialty: s.specialty })), [jobs, state.job])

  return <div>
    <Stack horizontal spacing={16} className='p-4 bg-gray-100'>
      <Stack styles={{ root: { width: '100%' } }}>
        <Label required htmlFor='startDate'>Start Date</Label>
        <input className={errors.startDate ? 'hasError' : ''} type="date" id="startDate" name="startDate" onChange={handleDateChange('startDate')} />
        {errors.startDate && <ErrorMessage>{errors.startDate}</ErrorMessage>}
      </Stack>
      <Stack styles={{ root: { width: '100%' } }}>
        <Label required htmlFor='endDate'>End Date</Label>
        <input className={errors.endDate ? 'hasError' : ''} type="date" id="endDate" name="endDate" onChange={handleDateChange('endDate')} />
        {errors.endDate && <ErrorMessage>{errors.endDate}</ErrorMessage>}
      </Stack>
    </Stack>
    <Stack horizontal className='p-4' spacing={16}>
      <FWTextField required label='Bill Rate' onChange={handleChange('billRate')} errorMessage={errors.billRate} />
      <FWTextField label='Guaranteed Hours' type='number' min={1} onChange={handleChange('guaranteedHours')} />
    </Stack>
    <Stack horizontal className='p-4 bg-gray-100' spacing={16}>
      <Dropdown
        required
        label='On Call'
        selectedKey={state.onCall}
        options={[{ key: 'yes', text: 'Yes' }, { key: 'no', text: 'No' }]}
        styles={{ root: { width: '100%' } }}
        onChange={handleDropdown('onCall')}
        errorMessage={errors.onCall} />
      <Dropdown
        required
        label='Shift Type'
        selectedKey={state.shiftType}
        options={[{ key: 'travel', text: 'Travel' }, { key: 'permanent', text: 'Permanent' }]}
        styles={{ root: { width: '100%' } }}
        onChange={handleDropdown('shiftType')}
        errorMessage={errors.shiftType} />
    </Stack>
    <Stack className='p-4'>
      <Label required>Discipline</Label>
      <Select
        required
        options={disciplines.map(c => ({ value: c.discipline, label: c.discipline, id: c.id }))}
        placeholder=''
        value={state.discipline}
        onChange={handleDiscipline as any}
        error={errors.discipline}
      />
      {errors.discipline && <ErrorMessage>{errors.discipline}</ErrorMessage>}
    </Stack>
    {state.discipline && <Stack className="p-4 bg-gray-100 slide-in">
      <Label required>Job Title</Label>
      <Select
        placeholder=''
        options={jobs.map(s => ({ value: s.job, label: s.job, id: s.id }))}
        onChange={handleSelect('job')}
        value={state.job}
      />
      {(state.discipline && errors.job) && <ErrorMessage>{errors.job}</ErrorMessage>}
    </Stack>}
    {(state.job && specialties.length)
      ? <Stack className="p-4 slide-in">
        <Label>Specialty</Label>
        <Select
          placeholder=''
          options={specialties}
          onChange={handleSelect('specialty')}
          value={state.specialty}
          error={errors.specialty}
        />
      </Stack>
      : null}
    <div className="p-4">
      <FWTextField label='Description/Comments' multiline onChange={handleChange('comments')} />
    </div>
  </div>
}
