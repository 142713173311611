export const GET_USERS = `
  query GetUsers($filters: UserFilters) {
    users(filters: $filters) {
      created
      fullName
      userName
      email
      phoneNumber
      admin
      orgAdmin
      organization {
        name
        type
        description
      }
    }
    analytics {
      filledRequisitions
      openRequisitions
      candidates
      submittedCandidates
    }
  }
`

export const GET_ORG_USERS = `
  query GetUsers($filters: UserFilters) {
    user {
      created
      fullName
      userName
      email
      phoneNumber
      admin
      orgAdmin
      contractSigner
      organization {
        name
        type
        description
      }
    }
    users(filters: $filters) {
      id
      created
      fullName
      userName
      email
      phoneNumber
      admin
      orgAdmin
      contractSigner
    } 
  }
`

export const GET_USER_PERMISSIONS = `
  query GetUserPermissions {
    user {
      organization {
        type
      }
    }
  }
`

export const CREATE_USER = `
  mutation CreateUser($args: UserInput!) {
    createUser(args: $args) {
      success
      messages {
        message
      }
    }
  }
`

export const UPDATE_USER = `
  mutation UpdateUser($id: String!, $args: UserInput!) {
    updateUser(id: $id, args: $args) {
      success
      messages {
        message
      }
    }
  }
`

export const DELETE_USER = `
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      success
      messages {
        message
      }
    }
  }
`
