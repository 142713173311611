import { useLoaderData } from 'react-router-dom'
import { Stack } from '../Common/Common'
import { Header } from '../Common/Header'
import { APIClient } from '../util/commonTypes'
import { EmailIcon, PhoneIcon, ThreeDotsVerticalIcon } from '../assets/Icons'
import { DefaultButton, DirectionalHint, IContextualMenuProps } from '@fluentui/react'
import { useMemo } from 'react'
import { NotFound } from '../NotFound/NotFound'
import { NonFatalError } from '../Common/Error'
import { RequisitionsTable } from '../Requisition/RequisitionsTable'
import { formatPhoneNumber } from 'react-phone-number-input'
import { HomeIcon } from '../assets/Home'
import { theme } from '../util/theme'

export function Client () {
  const data: any = useLoaderData()
  const clients: APIClient[] = data?.clients ?? {}

  const menuProps: IContextualMenuProps = useMemo(() => {
    const props = {
      directionalHintForRTL: DirectionalHint.topCenter,
      directionalHintFixed: false,
      items: [
        { key: 'ban', text: 'Ban', onClick: () => { console.log('Handle CLick') } }
      ]
    }
    return props
  }, [])

  if (data.errors) return <NonFatalError errors={data.errors} />

  if (!clients.length) return <NotFound message='Not found' />

  const client = clients[0]
  const { address, email, phoneNumber } = client
  const requisitions = client.requisitions

  return <main>
    <Stack className='bg-gray-100 p-4 py-4'>
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center' spacing={8}>
        <h1 className='font-medium text-blue-900'>{client.name}</h1>
        <DefaultButton
          menuProps={menuProps}
          className='clear-btn'
          styles={{ menuIcon: { display: 'none' }, flexContainer: { border: 'none' } }}
          style={{ border: 'none' }}>
          <ThreeDotsVerticalIcon />
        </DefaultButton>
      </Stack>
      <Stack className='' spacing={16}>
        <Stack wrap={true} horizontal horizontalAlign='space-evenly' styles={{ inner: { gap: 15 } }}>

          <Stack horizontal spacing={6} verticalAlign='center'>
            <PhoneIcon size={20} color={theme.blue.hex()} />
            <span>{formatPhoneNumber(phoneNumber)}</span>
          </Stack>
          <Stack horizontal spacing={6} verticalAlign='center'>
            <HomeIcon color={theme.blue.hex()} size={20} strokeWidth={1.5} />
            <Stack horizontalAlign='center'>
              <span>{address.address}</span>
              <span>{address.city}, {address.state} {address.zipcode.split('-')[0]}</span>
            </Stack>
          </Stack>
          <Stack horizontal spacing={6} verticalAlign='center'>
            <EmailIcon color={theme.blue.hex()} size={20} />
            <span>{email}</span>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    <section>

    </section>

    <div>
      <Stack>
        {requisitions?.length
          ? <Header>
            <h2 className='font-normal text-blue-900'>Requisitions</h2>
          </Header>
          : null}
          {/* TODO: ADD HANDLERS */}
        <RequisitionsTable
          data={requisitions}
          handleClick={(value: any) => { }}
          closeReq={async (id) => { }}
          handleDelete={async (id) => { }}
          setReq={() => {}}
        />
      </Stack>
    </div>
  </main>
}
