import { DefaultButton, Modal } from '@fluentui/react'
import { PrimaryButton, Stack } from './Common'
import { useMemo } from 'react'

export const WarningModal = ({ isOpen, title, description, closeModal, footer, handleConfirm }: { isOpen: boolean; closeModal: () => void; title: string; description?: string; footer?: any, handleConfirm?: () => void }) => {
  const defaultFooter = useMemo(() => {
    if (!handleConfirm) return null
    return <Stack horizontal spacing={8} horizontalAlign='end'>
      <DefaultButton onClick={closeModal}>
        Cancel
      </DefaultButton>
      <PrimaryButton onClick={handleConfirm}>
        Confirm
      </PrimaryButton>
    </Stack>
  }, [closeModal, handleConfirm])

  return <Modal
    styles={{
      main: { padding: '24px', maxWidth: 600, display: 'flex' }
    }}
    isOpen={isOpen}
    onDismiss={closeModal}
  >
    <Stack verticalAlign='space-evenly' spacing={16} verticalFill>
      <Stack>
        <h2 style={{ fontWeight: 400 }}>{title}</h2>
        {description && <p>{description}</p>}
      </Stack>
      <Stack horizontal horizontalAlign='end' spacing={16}>
        {footer || defaultFooter}
      </Stack>
    </Stack>
  </Modal>
}
