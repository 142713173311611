import { ComboBox, Dropdown, IComboBoxOption, IDropdownOption } from '@fluentui/react'
import { APIDiscipline } from '../util/commonTypes'
import { states } from '../util/states'
import { Stack } from '../Common/Common'
import { useWindowSize } from '../hooks/resize'

interface ICandidateSearch {
  state: any
  handleChange: (path: string, options?: { multiselect?: boolean; }) => (event: any, value: any) => void
  disciplines: APIDiscipline[]
}

export function CandidateSearch ({ handleChange, state, disciplines }: ICandidateSearch) {
  const [width] = useWindowSize()
  const stateOptions: IComboBoxOption[] = states.arrayOf().map(s => ({ key: s, text: s + ' ' }))
  const statusOptions: IDropdownOption[] = [{ key: '', text: 'Candidate status', hidden: true }, { key: 'active', text: 'Active' }, { key: 'inactive', text: 'Inactive' }]
  const disciplineOptions: IComboBoxOption[] = disciplines?.map(d => ({ key: d.id, text: d.discipline }))

  return <Stack className='bg-gray-100 p-0 py-4 sm:p-8' wrap={width < 640} horizontal spacing={16} horizontalAlign='space-evenly'>
    <ComboBox
      multiSelect
      selectedKey={state.disciplines}
      placeholder='Select a discipline'
      options={disciplineOptions}
      onChange={handleChange('disciplines', { multiselect: true })}
      styles={{ container: { width: '100%' }, optionsContainer: { width: '100px', maxHeight: '256px' } }}
    />
    <ComboBox
      multiSelect
      selectedKey={state.states}
      placeholder='Select a state'
      options={stateOptions}
      onChange={handleChange('states', { multiselect: true })}
      styles={{ container: { width: '100%' }, optionsContainer: { width: '100px', maxHeight: '256px' } }}
    />
    <Dropdown
      selectedKey={state.status ? state.status.key : ''}
      onChange={handleChange('status')}
      placeholder='Candidate status'
      options={statusOptions}
      styles={{ root: { width: '100%' } }}
    />
  </Stack>
}
