import React, { PropsWithChildren, AriaAttributes, useCallback, JSX } from 'react'
import { classNames as classNamesFunc } from '../util/helpers'

interface TableBase extends AriaAttributes {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

interface RowProps extends TableBase {
  tabIndex?: number
  onClick?: (event?: React.MouseEvent<HTMLTableRowElement>) => void
  children?: React.ReactNode
}

export const Row: React.FC<RowProps> = function (props) {
  const { children, onClick } = props
  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault()
      onClick && onClick()
    }
  }, [onClick])

  return (
    <tr
      {...props}
      onKeyDown={handleKeyDown}
    >
      {children}
    </tr>
  )
}

export interface RowCellProps extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> {
  row?: number
}

export const RowCell: React.FunctionComponent<RowCellProps> = function (props) {
  const { children, ...rest } = props
  return (
    <td
      {...rest}
    >
      {children}
    </td>
  )
}

interface HeaderProps extends TableBase {}

export const Header: React.FunctionComponent<HeaderProps> = function (props) {
  const { children, className, style } = props
  return (
    <thead
      className={className}
      style={style}
      {...props}
    >
      <tr>{children}</tr>
    </thead>
  )
}

interface HeaderCellProps extends TableBase {}

export const HeaderCell: React.FunctionComponent<HeaderCellProps> = function (props) {
  const { children, className, style } = props
  return (
    <th
      className={className}
      style={style}
      {...props}
    >
      {children}
    </th>
  )
}

interface BodyProps extends TableBase {}

export const Body: React.FunctionComponent<BodyProps> = function (props) {
  const { children, className, style } = props
  return (
    <tbody
      className={className}
      style={style}
    >
      {children}
    </tbody>
  )
}

interface CaptionProps extends TableBase {
  id: string
}

export function Caption (props: PropsWithChildren<CaptionProps>) {
  return <caption id={props.id}>{props.children}</caption>
}

type ClassNamesArgs = string | string[] | { [className: string]: boolean }[] | number | number[]

interface TableProps {
  id: string
  className?: string
  classNames?: {
    table?: ClassNamesArgs
    container?: ClassNamesArgs
  }
  styles?: {
    table?: React.CSSProperties
    container?: React.CSSProperties
  }
  style?: React.CSSProperties
}

export function Table (props: PropsWithChildren<TableProps>): JSX.Element | null {
  const { id, children, className, style, classNames, styles } = props
  return (
    <div
      id={`${id}-container`}
      className={classNamesFunc(className, classNames?.container) + 'table-container'}
      style={Object.assign({}, style, styles?.container ?? {})}
    >
      <table
        id={id}
        role='grid'
        className={classNamesFunc(classNames?.table)}
        style={styles?.table}
      >
        {children}
      </table>
    </div>
  )
}
