import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from '@fluentui/react'
import { APIRequisition, ISelectOption } from '../util/commonTypes'
import { api } from '../util/api'
import { GET_CANDIDATES_FOR_SUBMITTAL } from '../queries/Candidates'
import { Select } from '../Common/Select'
import { PrimaryButton, Stack } from '../Common/Common'
import { SUBMIT_CANDIDATE_REQUISITION } from '../queries/Requisitions'
import { Toast } from '../Toast/Toast'
import { useRevalidator } from 'react-router-dom'

interface ICandidateSubmittal {
  requisition?: APIRequisition
  handleClose: () => void
}

export function CandidateSubmittal ({ requisition, handleClose }: ICandidateSubmittal) {
  const [candidates, setCandidates] = useState<any[]>([])
  const [selected, setSelected] = useState<ISelectOption | undefined>()
  const revalidator = useRevalidator()

  const fetchCandidates = useCallback(async () => {
    const { candidates } = await api.query(GET_CANDIDATES_FOR_SUBMITTAL, {
      filters: {
        job: requisition?.job_id,
        hasSpecialty: !!requisition?.specialty
      }
    })
    setCandidates(candidates)
  }, [requisition?.job_id, requisition?.specialty])

  const handleSelect = useCallback((value: any) => {
    setSelected(value)
  }, [])

  const handleSubmit = useCallback(async () => {
    if (!selected?.id || !requisition?.id) return
    const res = await api.query(SUBMIT_CANDIDATE_REQUISITION, {
      candidateId: selected.id,
      requisitionId: requisition.id
    })

    if (res?.submitCandidate?.success) {
      handleClose()
      revalidator.revalidate()
    } else if (res.errors?.length) {
      const errors = res.errors as string[]
      errors.forEach(e => Toast.makeText({ message: e, duration: 3000, type: 'alert' }))
    }
  }, [selected, requisition, handleClose, revalidator])

  useEffect(() => {
    fetchCandidates()
  }, [fetchCandidates])

  return <Modal
    isOpen={!!requisition}
    onDismiss={handleClose}
    styles={{
      root: {
        overflow: 'visible'
      },
      scrollableContent: {
        overflowY: 'visible'
      },
      main: {
        overflowY: 'visible',
        display: 'flex',
        alignItems: 'center'
      }
    }}
  >
    <Stack verticalFill style={{ padding: '10px 20px' }} spacing={16}>
      {
        candidates?.length
          ? <>
            <h1 className='m-0'>Select a candidate</h1>
            <Select
              options={candidates.map(s => ({ value: `${s.firstName} ${s.lastName}`, label: `${s.firstName} ${s.lastName}`, id: s.id }))}
              onChange={handleSelect}
              noOptionsMessage={() => <b>No Candidates Meet Criteria</b>
              }
            />
            <PrimaryButton onClick={handleSubmit} disabled={!selected?.id}>Submit</PrimaryButton>
          </>
          : <span className='text-xl'>No Candidates Meet Criteria</span>
      }
    </Stack>
  </Modal>
}
