import { useLoaderData } from 'react-router-dom'
import { APICandidate } from '../util/commonTypes'
import { NotFound } from '../NotFound/NotFound'
import { Stack } from '../Common/Common'
import { EmailIcon, PhoneIcon } from '../assets/Icons'
import { HomeIcon } from '../assets/Home'
import { theme } from '../util/theme'
import { formatPhoneNumber } from 'react-phone-number-input'
import { NonFatalError } from '../Common/Error'

export function Candidate () {
  const data = useLoaderData() as any

  if (data?.errors) return <NonFatalError errors={data.errors} />
  if (!data.candidates.length) return <NotFound />

  const { firstName, lastName, address, discipline, dob, email, jobs, mobileNumber, medicalLicense, npi, specialties, ...candidate } = data.candidates[0] as APICandidate

  return <main>
    <div className='bg-gray-100 py-8'>
      <h1 className='sm:text-3xl text-center m-0'>{firstName} {lastName}</h1>
    </div>

    <Stack className='bg-gray-100 p-8 pt-0' spacing={16}>
      <Stack wrap={true} horizontal horizontalAlign='space-evenly' styles={{ inner: { gap: 15 } }}>
        <Stack horizontal spacing={6} verticalAlign='center'>
          <PhoneIcon size={20} color={theme.blue.hex()} />
          <span>{formatPhoneNumber(mobileNumber)}</span>
        </Stack>
        <Stack horizontal spacing={6} verticalAlign='center'>
          <HomeIcon color={theme.blue.hex()} size={20} strokeWidth={1.5} />
          <Stack horizontalAlign='center'>
            <span>{address.address}</span>
            <span>{address.city}, {address.state} {address.zipcode.split('-')[0]}</span>
          </Stack>
        </Stack>
        <Stack horizontal spacing={6} verticalAlign='center'>
          <EmailIcon color={theme.blue.hex()} size={20} />
          <span>{email}</span>
        </Stack>
      </Stack>
      <Stack horizontal horizontalAlign='center' spacing={20} wrap>
        <span><b>Discipline: </b>{discipline}</span>
        <span><b>Jobs: </b> {jobs?.join(', ')}</span>
        {specialties?.length ? <span><b>Specialties: </b> {specialties?.join(', ')}</span> : null}
        <span><b>DOB: </b>{dob}</span>
        {medicalLicense && <span><b>Medical License: </b>{medicalLicense}</span>}
        {npi && <span><b>NPI: </b>{npi}</span>}
      </Stack>
    </Stack>

    <section>

    </section>
    {candidate.resumeMimeType?.includes('image')
      ? <img src={`data:${candidate.resumeMimeType};base64,${candidate.resume}`} alt='resume' className='mx-auto' />
      : <iframe src={`data:application/pdf;base64,${candidate.resume}`} className='mx-auto' style={{ height: '100vh', width: '90vw' }} />}
  </main>
}
