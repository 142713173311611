import { useLoaderData } from 'react-router-dom'
import { Header } from '../Common/Header'
import { NotFound } from '../NotFound/NotFound'
import { APIRequisition } from '../util/commonTypes'
import { NonFatalError } from '../Common/Error'
import { MailLink, PhoneLink, Stack } from '../Common/Common'
import { StatusPill } from './RequisitionsTable'
import { formatDate } from '../util/helpers'
import { CalendarIcon, ThreeDotsVerticalIcon, WorldIcon } from '../assets/Icons'
import { theme } from '../util/theme'
import { useWindowSize } from '../hooks/resize'
import { useMemo } from 'react'
import { DefaultButton } from '@fluentui/react'
import { formatPhoneNumber } from 'react-phone-number-input'

function DesktopView ({ requisition }: { requisition?: APIRequisition }) {
  if (!requisition) return null
  return <>
    <Stack spacing={8} wrap horizontal horizontalAlign='space-between' className='px-4 py-2 bg-gray-100 mt-0'>
      <Stack spacing={8}>
        <span><b>Discipline:</b> {requisition.discipline}</span>
        <span><b>Job:</b> {requisition.job}</span>
        {requisition.specialty && <span><b>Specialty:</b> {requisition.specialty}</span>}
      </Stack>
      <Stack spacing={8} horizontalAlign='end'>
        <Stack horizontal spacing={8}>
          <b>On Call:</b>
          <span>{requisition.onCall?.toUpperCase()}</span>
        </Stack>
        <Stack horizontal spacing={8}>
          <b>Shift Type:</b>
          <span>{requisition.shiftType?.toUpperCase()}</span>
        </Stack>
        <Stack spacing={8} horizontal horizontalAlign='end'>
          <Stack horizontal>
            <CalendarIcon size={24} color={theme.blue.hex()} />{formatDate(requisition.startDate)} - {formatDate(requisition.endDate)}
          </Stack>
          <Stack horizontal>
            <WorldIcon color={theme.blue.hex()} />
            {requisition.city}, {requisition.state}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    <Stack>
      <Stack horizontal horizontalAlign='start' className='px-4 py-2 pb-4 bg-gray-100'>
        {requisition.comments && <Stack className='p-4'>
          <span>Comments/Description:</span> {requisition.comments}
        </Stack>}
      </Stack>
    </Stack>
  </>
}

function MobileView ({ requisition }: { requisition?: APIRequisition }) {
  if (!requisition) return null
  return <>
    <Stack horizontal spacing={8} verticalAlign='center' horizontalAlign='space-between' className='p-4 bg-gray-100' wrap>
      <Stack>
        <b>Discipline:</b>
        <span>{requisition.discipline}</span>
      </Stack>
      <Stack horizontalAlign='end'>
        <b>Job:</b>
        <span>{requisition.job}</span>
      </Stack>
      {requisition.specialty && <Stack>
        <b>Specialty:</b>
        <span>{requisition.specialty}</span>
      </Stack>}
    </Stack>
    <Stack horizontal horizontalAlign='space-between' className='p-4 bg-gray-100'>
      <Stack>
        <b>Date Period:</b>
        {formatDate(requisition.startDate)} - {formatDate(requisition.endDate)}
      </Stack>
      <Stack horizontalAlign='end'>
        <b>Region:</b>
        {requisition.city}, {requisition.state}
      </Stack>
    </Stack>
    <Stack horizontal horizontalAlign='space-between' className='p-4 bg-gray-100'>
      <Stack>
        <b>Shift Type:</b>
        {requisition.shiftType?.toUpperCase()}
      </Stack>
      <Stack horizontalAlign='end'>
        <b>On Call:</b>
        {requisition.onCall?.toUpperCase()}
      </Stack>
    </Stack>
  </>
}

export function Requisition () {
  const data = useLoaderData() as { requisitions: APIRequisition[], errors?: string[] }
  const [width] = useWindowSize()

  const requisition = useMemo(() => data.requisitions.length ? data.requisitions[0] : undefined, [data.requisitions])

  const view = useMemo(() => width < 659 ? <MobileView requisition={requisition} /> : <DesktopView requisition={requisition} />, [width, requisition])

  if (data.errors?.length) return <NonFatalError errors={data.errors} />

  if (!requisition) return <NotFound />

  return <main>
    <h1 className='text-4xl text-center p-4 bg-gray-100 m-0'>Requisition</h1>
    <Header>
      <Stack>
        <span>Bill Rate: <b className='text-2xl'>${requisition.billRate}</b></span>
        {requisition.guaranteedHours && <span>Guaranteed Hours: {requisition.guaranteedHours}</span>}
      </Stack>
      <Stack horizontalAlign='end'>
        <StatusPill status={requisition.status} size='med' />
      </Stack>
    </Header>

    {view}

    <Stack className='p-4'>
      <h2>{requisition.status === 'CLOSED' && requisition.candidates?.length && requisition.candidates[0].accepted ? 'Accepted Candidate' : 'Submitted Candidates'}</h2>
      {requisition.candidates?.map((c, k) => <Stack key={k} spacing={10} className={`border-2 p-2 rounded ${c.accepted ? 'border-blue-200' : ''}`}>
        <Stack horizontal horizontalAlign='space-between'>
          <a href={`/candidates/${c.id}`} target='_blank' rel='noreferrer' className='underline'>{c.name}</a>
          {requisition.status !== 'CLOSED' && <DefaultButton
            menuProps={{
              items: []
              // items: candidateItems(c.id)
            }}
            className='clear-btn p-0'
            styles={{ menuIcon: { display: 'none' } }}
            style={{ border: 'none' }}
          >
            <ThreeDotsVerticalIcon />
          </DefaultButton>}
        </Stack>
        <Stack horizontal horizontalAlign='space-between'>
          <PhoneLink url={formatPhoneNumber(c.mobileNumber)} />
          <MailLink url={c.email} />
        </Stack>
      </Stack>)}
    </Stack>
  </main>
}
