export function LogoIcon () {
  return <svg xmlns="http://www.w3.org/2000/svg" width="182" height="31" viewBox="0 0 276 31" fill="none">
    <path d="M15.6218 22.8586C16.736 20.7089 16.8146 20.5845 20.5852 12.6918C23.471 6.6979 25.0875 3.26678 25.472 2.39845C26.0488 2.48062 26.2803 2.52282 26.549 2.52282C26.8178 2.52282 27.0493 2.48062 27.5868 2.39845L27.7025 3.30898C28.087 7.15316 28.2028 8.18583 28.3578 10.0047C28.4736 10.873 28.7031 13.0227 29.0503 16.4116C30.0116 25.3814 30.3196 27.6533 30.8199 30.4648C29.5878 30.3404 28.9346 30.3005 28.0498 30.3005C27.1651 30.3005 26.6648 30.3426 25.5878 30.4648C25.5113 26.8271 24.6638 16.5782 23.934 10.873C19.01 20.9998 15.8161 27.8598 14.9314 30.3005C14.6999 30.2583 14.5841 30.2583 14.3918 30.2583C14.1996 30.2583 14.0466 30.2583 13.7758 30.3005C11.7376 25.6701 8.81247 19.3053 5.4264 12.1966L5.00262 11.2883C4.23362 16.7847 3.19382 28.1108 3.2331 30.467C2.4641 30.3427 2.0796 30.3027 1.61655 30.3027C1.19277 30.3027 0.808275 30.3449 0 30.467C0.768998 26.4985 1.65376 20.4224 2.77005 11.6192L3.38607 6.70012C3.46256 6.20489 3.65481 4.67476 3.92561 2.40067C4.42587 2.48284 4.69461 2.52504 4.96335 2.52504C5.27136 2.52504 5.50288 2.48284 6.00108 2.40067C7.50186 5.87399 11.4254 14.2219 15.2725 22.1568L15.6218 22.8586Z" fill="#fff" />
    <path d="M38.7476 2.72935C39.6716 2.72935 40.1719 2.68716 41.1331 2.56501C40.8251 6.78007 40.7486 9.88029 40.7486 16.989C40.7486 21.1219 40.8251 25.5457 40.9409 27.9842C41.7491 28.0264 41.9414 28.0264 42.2494 28.0264C44.7507 28.0264 47.9053 27.8198 50.4066 27.4889C50.2536 28.2751 50.2143 28.646 50.2143 29.1412C50.2143 29.5965 50.2536 29.8852 50.4066 30.4648C48.1761 30.3826 44.2132 30.3005 42.9812 30.3005C41.6727 30.3005 38.0943 30.3826 35.7873 30.4648C36.0561 27.4467 36.1718 22.5299 36.1718 15.832C36.1718 9.01196 36.0954 6.9866 35.7873 2.56501C37.0938 2.68716 37.7863 2.72935 38.7476 2.72935Z" fill="#fff" />
    <path d="M68.1452 26.7427L68.0295 27.0736C67.7215 27.8998 67.645 28.1885 67.5292 28.9347C65.3752 30.4226 63.6821 30.96 61.3731 30.96C55.7937 30.96 52.2939 27.0337 52.2939 20.8332C52.2939 14.5084 55.6035 10.6243 61.0279 10.6243C66.0284 10.6243 68.877 13.8488 68.877 19.4697C68.877 19.6762 68.8377 20.2136 68.8005 20.7933C67.1075 20.7111 65.4145 20.6689 62.7994 20.6689C60.9906 20.6689 59.0681 20.7111 56.9906 20.7511C56.9513 20.9998 56.9513 21.2885 56.9513 21.3307C56.9513 25.9188 59.1446 28.7703 62.6465 28.7703C64.569 28.7703 66.1855 28.0264 67.7628 26.4962L68.1452 26.7427ZM61.7969 18.9722C63.3369 18.9722 63.6057 18.9722 64.5276 18.89C64.4511 16.8647 64.3746 15.9963 64.1431 15.128C63.6429 13.1848 62.5659 12.1522 60.9886 12.1522C58.6795 12.1522 57.1808 14.6728 57.0258 18.93C57.987 18.9722 58.1793 18.9722 60.7963 18.9722H61.7969Z" fill="#fff" />
    <path d="M87.5376 26.7427L87.4219 27.0736C87.1138 27.8998 87.0373 28.1885 86.9216 28.9347C84.7676 30.4226 83.0745 30.96 80.7655 30.96C75.1861 30.96 71.6863 27.0337 71.6863 20.8332C71.6863 14.5084 74.9959 10.6243 80.4202 10.6243C85.4208 10.6243 88.2694 13.8488 88.2694 19.4697C88.2694 19.6762 88.2301 20.2136 88.1929 20.7933C86.4999 20.7111 84.8068 20.6689 82.1918 20.6689C80.383 20.6689 78.4605 20.7111 76.383 20.7511C76.3437 20.9998 76.3437 21.2885 76.3437 21.3307C76.3437 25.9188 78.537 28.7703 82.0368 28.7703C83.9593 28.7703 85.5758 28.0264 87.1531 26.4962L87.5376 26.7427ZM81.1893 18.9722C82.7293 18.9722 82.9981 18.9722 83.92 18.89C83.8435 16.8647 83.767 15.9963 83.5355 15.128C83.0353 13.1848 81.9582 12.1522 80.381 12.1522C78.0719 12.1522 76.5732 14.6728 76.4182 18.93C77.3794 18.9722 77.5716 18.9722 80.1887 18.9722H81.1893Z" fill="#fff" />
    <path d="M105.855 18.5169C105.855 22.9385 106.008 28.1885 106.2 30.4626C105.239 30.3382 104.778 30.2982 104.278 30.2982C103.738 30.2982 103.277 30.3404 102.432 30.4626C102.74 26.9915 102.816 24.0978 102.816 16.1607C102.816 8.96977 102.701 5.57862 102.432 2.56279C103.164 2.68715 103.585 2.72713 104.278 2.72713C104.97 2.72713 105.431 2.68493 106.2 2.56279C106.047 4.42159 105.855 9.96024 105.855 13.3914V14.4662C106.24 14.5084 106.471 14.5084 106.471 14.5084L109.973 14.5506L113.82 14.5928L117.706 14.5506L121.169 14.5084C121.245 14.5084 121.477 14.5084 121.785 14.4662C121.824 14.011 121.824 13.5157 121.824 13.3914C121.824 9.96024 121.632 4.46379 121.44 2.56279C122.248 2.68715 122.709 2.72713 123.401 2.72713C124.094 2.72713 124.518 2.68493 125.247 2.56279C124.979 5.49645 124.9 9.05194 124.9 16.1607C124.9 24.0134 124.977 26.9893 125.247 30.4626C124.4 30.3382 123.939 30.2982 123.401 30.2982C122.862 30.2982 122.44 30.3404 121.44 30.4626C121.708 28.0641 121.861 23.0629 121.861 18.3104C121.861 17.6486 121.861 17.1112 121.785 16.3272L121.208 16.285C120.131 16.285 120.055 16.285 113.822 16.2429H109.936C107.089 16.285 106.934 16.285 105.857 16.325V18.5169H105.855Z" fill="#fff" />
    <path d="M146.178 26.5362L146.101 26.9071C145.909 27.6089 145.87 27.7754 145.87 28.6015C143.484 30.4204 141.907 30.9978 139.329 30.9978C133.789 30.9978 130.287 27.1536 130.287 21.0775C130.287 17.9351 131.096 15.3723 132.712 13.5135C134.252 11.6947 136.29 10.8685 139.137 10.8685C144.022 10.8685 146.831 14.0087 146.831 19.4652C146.831 19.7539 146.831 20.2514 146.792 20.7466C145.562 20.6644 143.753 20.6223 140.52 20.6223C138.134 20.6223 136.249 20.6644 133.634 20.7466V21.6571C133.634 26.2875 136.173 29.3455 140.02 29.3455C142.366 29.3455 144.367 28.3528 145.944 26.4518L146.178 26.5362ZM143.176 15.2524C142.444 13.227 141.022 12.1522 139.06 12.1522C136.059 12.1522 133.944 14.8815 133.673 19.1365C134.827 19.1787 136.596 19.2187 139.559 19.2187C142.099 19.2187 142.484 19.2187 143.792 19.0544C143.677 17.1956 143.524 16.1629 143.176 15.2524Z" fill="#fff" />
    <path d="M151.681 15.4167C151.757 14.8371 151.796 14.5906 151.796 14.2597C151.796 13.9288 151.757 13.6801 151.681 13.1027C153.874 11.5326 155.683 10.8708 157.837 10.8708C161.684 10.8708 163.993 13.227 163.993 17.1956C163.993 17.9395 163.954 18.8478 163.916 19.9227C163.877 21.4928 163.84 22.5677 163.84 24.1378C163.84 26.6584 163.879 27.6089 164.032 28.1063C164.224 28.8081 164.688 29.1812 165.341 29.1812C165.572 29.1812 165.765 29.139 166.149 29.099V29.8852C165.111 30.3404 164.417 30.5048 163.611 30.5048C162.07 30.5048 161.225 29.7186 160.993 28.0663C159.3 30.0495 157.415 31 155.185 31C151.991 31 149.99 29.0568 149.99 25.9588C149.99 24.2222 150.606 22.7342 151.683 21.9081C152.683 21.1219 153.8 20.7089 156.454 20.2536C159.224 19.7584 160.072 19.4674 161.07 18.7657V18.6835C161.07 16.4516 161.07 16.3272 160.762 15.3767C160.222 13.6001 158.684 12.5253 156.76 12.5253C154.759 12.5253 153.029 13.5579 152.067 15.4189H151.681V15.4167ZM160.683 20.5023C155.799 21.6594 155.644 21.7016 154.875 22.1546C153.682 22.8586 153.105 23.9734 153.105 25.5435C153.105 27.7332 154.529 29.2633 156.568 29.2633C158.261 29.2633 159.993 28.1485 160.991 26.4118C160.991 25.337 160.991 25.0883 161.031 24.2621V23.2694L161.107 20.7888V20.4157L160.683 20.5023Z" fill="#fff" />
    <path d="M171.267 0.166559C171.921 0.166559 172.229 0.124364 172.845 0.0421947C172.729 2.19192 172.613 10.0868 172.613 14.9637C172.613 21.8237 172.729 28.2729 172.845 30.4626C171.997 30.3382 171.652 30.2982 171.228 30.2982C170.804 30.2982 170.42 30.3404 169.574 30.4626C169.806 25.5035 169.806 24.2222 169.806 13.971C169.806 6.44695 169.729 2.89369 169.574 0.0421947C170.267 0.124364 170.612 0.166559 171.267 0.166559Z" fill="#fff" />
    <path d="M181.847 5.91174L181.808 6.40698L181.655 8.80543C181.579 9.59159 181.579 10.3333 181.539 11.5748L185.694 11.4926C185.579 11.9478 185.541 12.1544 185.541 12.3609C185.541 12.5674 185.581 12.774 185.694 13.1471H181.581C181.504 14.3863 181.504 14.4285 181.428 19.9671V23.6048C181.428 26.2075 181.504 26.9937 181.928 27.9442C182.391 28.9369 183.276 29.5143 184.469 29.5143C185.045 29.5143 185.469 29.4321 186.162 29.2256V30.1761C184.622 30.6713 183.892 30.8379 182.891 30.8379C180.198 30.8379 178.505 28.8947 178.505 25.8367C178.505 25.4658 178.544 24.3487 178.581 23.2317C178.658 21.6616 178.734 16.9491 178.734 14.5928C178.734 13.7667 178.734 13.6423 178.695 13.1471C177.31 13.1471 176.657 13.1893 176.233 13.2292C176.309 12.8983 176.349 12.734 176.349 12.4431C176.349 12.1544 176.309 11.9478 176.233 11.617H178.773V11.0795C178.773 10.4599 178.658 8.55892 178.542 7.15316C179.58 6.90443 180.735 6.367 181.736 5.7474L181.847 5.91174Z" fill="#fff" />
    <path d="M189.62 11.2838C189.62 7.39745 189.504 2.48062 189.351 0C190.083 0.124364 190.428 0.164338 191.16 0.164338C191.776 0.164338 192.045 0.122143 192.622 0C192.469 3.51329 192.429 6.57132 192.429 14.0954V14.9637C193.467 12.4009 195.737 10.873 198.546 10.873C200.279 10.873 201.856 11.4926 203.009 12.6096C204.239 13.8089 204.779 15.2968 204.779 17.5687C204.779 18.1883 204.74 19.2232 204.702 20.338C204.663 21.7438 204.626 23.1073 204.626 24.2244C204.626 26.8693 204.702 28.8525 204.818 30.4648C203.588 30.3005 203.433 30.3005 203.086 30.3005C202.701 30.3005 202.354 30.3426 201.546 30.4648C201.701 28.7703 201.854 22.7764 201.854 19.0566C201.854 16.989 201.506 15.5833 200.814 14.5928C200.045 13.5601 198.736 12.8983 197.351 12.8983C195.543 12.8983 193.965 13.891 193.157 15.5433C192.58 16.7425 192.425 18.0639 192.425 21.4528C192.425 25.297 192.502 28.5616 192.617 30.4626C191.617 30.3382 191.348 30.2982 190.962 30.2982C190.577 30.2982 190.269 30.3404 189.345 30.4626C189.537 27.3623 189.614 23.5182 189.614 15.128V11.2838H189.62Z" fill="#fff" />
    <path d="M223.94 15.1702C223.094 13.3936 221.17 12.1944 219.092 12.1944C215.361 12.1944 212.667 15.7498 212.667 20.7089C212.667 25.8344 215.553 29.4721 219.592 29.4721C221.67 29.4721 223.286 28.6859 224.903 26.9093L225.211 26.9915C225.095 27.5689 225.058 27.8598 225.058 28.2729C225.058 28.3972 225.058 28.5616 225.097 28.8103C223.365 30.2982 221.366 31 218.788 31C213.056 31 209.323 27.0315 209.323 20.9576C209.323 14.8393 213.285 10.873 219.402 10.873C221.672 10.873 223.21 11.3282 225.135 12.4853C224.711 13.3536 224.519 14.0154 224.326 15.1724H223.94V15.1702Z" fill="#fff" />
    <path d="M230.059 15.4167C230.135 14.8371 230.174 14.5906 230.174 14.2597C230.174 13.9288 230.135 13.6801 230.059 13.1027C232.252 11.5326 234.061 10.8708 236.215 10.8708C240.062 10.8708 242.371 13.227 242.371 17.1956C242.371 17.9395 242.332 18.8478 242.294 19.9227C242.255 21.4928 242.218 22.5677 242.218 24.1378C242.218 26.6584 242.257 27.6089 242.41 28.1063C242.602 28.8081 243.065 29.1812 243.719 29.1812C243.95 29.1812 244.142 29.139 244.527 29.099V29.8852C243.489 30.3404 242.795 30.5048 241.988 30.5048C240.448 30.5048 239.603 29.7186 239.371 28.0663C237.678 30.0495 235.793 31 233.562 31C230.369 31 228.368 29.0568 228.368 25.9588C228.368 24.2222 228.984 22.7342 230.061 21.9081C231.061 21.1219 232.177 20.7089 234.832 20.2536C237.602 19.7584 238.447 19.4674 239.448 18.7657V18.6835C239.448 16.4516 239.448 16.3272 239.14 15.3767C238.6 13.6001 237.062 12.5253 235.138 12.5253C233.137 12.5253 231.406 13.5579 230.445 15.4189H230.059V15.4167ZM239.061 20.5023C234.176 21.6594 234.021 21.7016 233.252 22.1546C232.06 22.8586 231.483 23.9734 231.483 25.5435C231.483 27.7332 232.907 29.2633 234.945 29.2633C236.638 29.2633 238.371 28.1485 239.369 26.4118C239.369 25.337 239.369 25.0883 239.408 24.2621V23.2694L239.485 20.7888V20.4157L239.061 20.5023Z" fill="#fff" />
    <path d="M256.839 14.5084C256.223 14.0532 255.686 13.8466 254.993 13.8466C253.416 13.8466 251.954 15.0858 251.415 16.9046C251.146 17.8152 251.07 19.3431 251.07 23.6825C251.07 25.9144 251.185 29.0568 251.299 30.4603C250.491 30.336 250.146 30.296 249.682 30.296C249.259 30.296 248.874 30.3382 248.029 30.4603C248.221 28.8902 248.337 25.2104 248.337 20.3757C248.337 17.3177 248.337 16.7803 248.029 11.1595C248.913 11.2838 249.221 11.3238 249.645 11.3238C250.03 11.3238 250.377 11.2816 251.107 11.1595V11.6147L251.067 13.267C251.028 14.1775 251.028 14.2997 251.028 15.4167C252.337 12.5652 254.222 10.8708 256.184 10.8708C256.645 10.8708 256.916 10.9529 257.337 11.1595C257.145 11.9856 257.029 12.8539 257.029 13.5157C257.029 13.6801 257.069 13.971 257.106 14.3419L256.839 14.5084Z" fill="#fff" />
    <path d="M275.347 26.5362L275.27 26.9071C275.078 27.6089 275.039 27.7754 275.039 28.6015C272.653 30.4204 271.076 30.9978 268.498 30.9978C262.958 30.9978 259.456 27.1536 259.456 21.0775C259.456 17.9351 260.264 15.3723 261.881 13.5135C263.421 11.6947 265.459 10.8685 268.306 10.8685C273.191 10.8685 276 14.0087 276 19.4652C276 19.7539 276 20.2514 275.961 20.7466C274.731 20.6644 272.922 20.6223 269.689 20.6223C267.303 20.6223 265.418 20.6644 262.803 20.7466V21.6571C262.803 26.2875 265.342 29.3455 269.189 29.3455C271.535 29.3455 273.536 28.3528 275.113 26.4518L275.347 26.5362ZM272.345 15.2524C271.613 13.227 270.191 12.1522 268.229 12.1522C265.228 12.1522 263.113 14.8815 262.842 19.1365C263.996 19.1787 265.765 19.2187 268.728 19.2187C271.268 19.2187 271.653 19.2187 272.961 19.0544C272.845 17.1956 272.692 16.1629 272.345 15.2524Z" fill="#fff" />
  </svg>
}
