import { gql } from '@apollo/client'

export const GET_PARTNERS = `
  query GetPartners($status: [PartnerStatus!]) {
    partners(status: $status) {
      id
      fullName
      organization
      phoneNumber
      jobTitle
      email
      status
    }
  }
`

export const GET_PARTNER = `
  query GetPartners($id: String) {
    partners(id: $id) {
      id
      fullName
      organization
      phoneNumber
      jobTitle
      email
      states
      partnerComment
      comments {
        comment
        user
      }
      docusignStatus
      status
    }
  }
`

export const CREATE_PARTNER = gql`
  mutation CreatePartner($args: PartnerInput!) {
    createPartner(args: $args) {
      success
      messages {
        arg
        message
        type
      }
    }
  }
`
export const UPDATE_PARTNER = `
  mutation UpdatePartner($id: String!, $args: PartnerInput!) {
    updatePartner(id: $id, args: $args) {
      success
      messages {
        arg
        message
        type
      }
    }
  }
`

export const ADD_PARTNER_COMMENT = `
  mutation AddPartnerComment($id: String!, $comment: String!) {
    addPartnerComment(id: $id, comment: $comment) {
      success
      messages {
        arg
        message
        type
      }
    }
  }
`
