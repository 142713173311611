import { Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react'
import { Table, Header, HeaderCell, Body, Row, RowCell } from '../Common/Table'
import './requisitions.css'
import { APIRequisition, IRequisition, OrganizationType } from '../util/commonTypes'
import { formatDate } from '../util/helpers'
import { DefaultButton, DirectionalHint, IContextualMenuProps } from '@fluentui/react'
import { ThreeDotsVerticalIcon, WorldIcon } from '../assets/Icons'
import { OrgContext } from '../Nav/OrgContext'
import { EmptyState } from '../NotFound/NotFound'
import { CardWrapperButtons, Stack } from '../Common/Common'
import { theme } from '../util/theme'
import { useWindowSize } from '../hooks/resize'

interface IRequisitionTable {
  data?: APIRequisition[]
  handleClick: (value: APIRequisition) => void
  closeReq: (id: string) => Promise<void>
  handleDelete: (id: string) => Promise<void>
  setReq: Dispatch<SetStateAction<APIRequisition | undefined>>
}

interface ICard {
  data: APIRequisition[]
  handleClick: (row:APIRequisition) => void
}

export const StatusPill = ({ status, size = 'small' }: { status: IRequisition['status'], size?: 'small' | 'med' | 'large' }) => {
  const sizeClassNames = useMemo(() => ({
    small: 'px-2 py-1 text-xs',
    med: 'px-3 py-1 text-base',
    large: ''
  }), [])

  const pill = useMemo(() => ({
    OPEN: <div className={`bg-green-100 text-center border border-green-400 text-green-700 rounded-full ${sizeClassNames[size]}`}>Open</div>,
    INPROGRESS: <div className={`bg-yellow-100 text-center border border-yellow-400 text-yellow-700 rounded-full ${sizeClassNames[size]}`}>In Progress</div>,
    CLOSED: <div className={`bg-red-100 text-center border border-red-400 text-red-700 rounded-full ${sizeClassNames[size]}`}>Closed</div>
  }), [size, sizeClassNames])

  return pill[status]
}

function ActionsButton ({ menuProps }: { menuProps: IContextualMenuProps }) {
  return <DefaultButton
    menuProps={menuProps}
    className='clear-btn'
    styles={{ menuIcon: { display: 'none' }, flexContainer: { border: 'none' } }}
    style={{ border: 'none' }}>
    <ThreeDotsVerticalIcon />
  </DefaultButton>
}

function Cards ({ data, handleClick }:ICard) {
  return <Stack spacing={16}>
  {data?.map((row, k) => <CardWrapperButtons key={k} onClick={() => { handleClick(row) }}>
    <Stack spacing={8}>
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
        <StatusPill status={row.status} />
        <span>{formatDate(row.startDate)} - {formatDate(row.endDate)}</span>
      </Stack>
      <Stack>
        <h2 className='text-blue-950 font-light'>{row.discipline}</h2>
      </Stack>
      <Stack wrap horizontal horizontalAlign='center' spacing={8}>
        <span><b>Job:</b> {row.job}</span>
        <span><b>Specialty:</b> {row.specialty ?? 'N/A'}</span>
      </Stack>
      <Stack horizontalAlign='end' horizontal spacing={4}>
        <WorldIcon size={20} color={theme.blue.hex()} /><span>{row.city}, {row.state}</span>
      </Stack>
    </Stack>
  </CardWrapperButtons>)}
</Stack>
}

export const RequisitionsTable = ({ data, handleClick, closeReq, handleDelete, setReq }: IRequisitionTable) => {
  const orgType = useContext(OrgContext)
  const [width] = useWindowSize()

  const menuProps = useCallback((requisition: APIRequisition) => {
    const menu = {
      items: [],
      directionalHintFixed: false,
      directionalHintForRTL: DirectionalHint.topCenter
    } as any
    const items = []

    if (requisition.status === 'CLOSED') return menu

    if (orgType === OrganizationType.CLIENT) {
      items.push({
        key: 'close',
        text: 'Close',
        onClick: () => { closeReq(requisition.id) }
      })
      items.push({
        key: 'delete',
        text: 'Delete',
        onClick: () => { handleDelete(requisition.id) }
      })
    } else items.push({ key: 'submit', text: 'Submit Candidate', onClick: () => { setReq(requisition) } })

    menu.items = items
    return menu
  }, [orgType, handleDelete, closeReq, setReq])

  const body = useMemo(() => data?.map((row, key) => {
    return <Row key={key} onClick={() => handleClick(row)}>
      <RowCell><StatusPill status={row.status} /></RowCell>
      <RowCell>{row.discipline}</RowCell>
      <RowCell>{row.job}</RowCell>
      <RowCell>{row.specialty ?? 'N/A'}</RowCell>
      <RowCell>{row.city}, {row.state}</RowCell>
      <RowCell className='date'>{formatDate(row.startDate)} - <br /> {formatDate(row.endDate)}</RowCell>
      <RowCell><ActionsButton menuProps={menuProps(row)} /></RowCell>
    </Row>
  }), [data, handleClick, menuProps])

  if (!data?.length) return <EmptyState text='No Requisitions Available' />

  if (width < 700) return <Cards data={data} handleClick={handleClick} />

  return <>
    <Table id='requisitions-table' classNames={{ table: 'table' }}>
      <Header>
        <HeaderCell>Status</HeaderCell>
        <HeaderCell>Discipline</HeaderCell>
        <HeaderCell>Job Title</HeaderCell>
        <HeaderCell>Specialty</HeaderCell>
        <HeaderCell>Region</HeaderCell>
        <HeaderCell>Start/End</HeaderCell>
        <HeaderCell style={{ width: 50 }}>Actions</HeaderCell>
      </Header>

      <Body>
        {body}
      </Body>
    </Table>
  </>
}
