import { IAddress } from '../util/commonTypes'

export function fillInAddress (autocomplete:any): IAddress {
  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace()
  let address1 = ''
  let city = ''
  let state = ''
  let postcode = ''

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types

    if (componentType.includes('street_number')) {
      address1 = `${component.long_name} ${address1}`
    }

    if (componentType.includes('route')) {
      address1 += component.short_name
    }

    if (componentType.includes('postal_code')) {
      postcode = `${component.long_name}${postcode}`
    }

    if (componentType.includes('postal_code_suffix')) {
      postcode = `${postcode}-${component.long_name}`
    }

    if (componentType.includes('locality') || componentType.includes('sublocality')) {
      city = component.long_name
    }

    if (componentType.includes('administrative_area_level_1')) {
      state = component.short_name
    }
  }

  // console.log('address1', address1)
  // console.log('city', city)
  // console.log('state', state)
  // console.log('postcode', postcode)
  // address1Field.value = address1
  // postalField.value = postcode

  // After filling the form with address components from the Autocomplete
  // prediction, set cursor focus on the second address line to encourage
  // entry of subpremise information such as apartment, unit, or floor number.
  // address2Field.focus()

  return {
    address: address1,
    city,
    state,
    zipcode: postcode
  }
}
