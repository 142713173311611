import { Stack as MSStack, IStackProps, ITextFieldProps, TextField, PrimaryButton as MSPrimaryButton, IButtonProps } from '@fluentui/react'
import { theme } from '../util/theme'
import styled from 'styled-components'
import React from 'react'
interface IStack extends IStackProps {
  spacing?: number
}

export function Stack ({ spacing, ...props }: IStack) {
  let tokens
  if (typeof props.tokens === 'object') {
    tokens = props.tokens
  }
  return <MSStack tokens={{
    childrenGap: spacing,
    maxHeight: tokens?.maxHeight,
    maxWidth: tokens?.maxWidth,
    padding: tokens?.padding
  }}
    {...props}>
    {props.children}
  </MSStack>
}

export function FWTextField (props: ITextFieldProps) {
  return <TextField styles={{ root: { width: '100%' } }} {...props} />
}

export function PrimaryButton ({ style, ...props }: IButtonProps) {
  return <MSPrimaryButton
    {...props}
    style={{
      backgroundColor: props.disabled ? '#5d5d5d6d' : '#0a408e',
      ...style
    }} />
}

export function DangerButton (props: IButtonProps) {
  return <MSPrimaryButton
    {...props}
    style={{
      backgroundColor: props.disabled ? '#5d5d5d6d' : theme.danger.hex(),
      borderColor: props.disabled ? '#5d5d5d6d' : theme.danger.hex(),
      ...props.style
    }} />
}

export function WarningButton (props: IButtonProps) {
  return <MSPrimaryButton {...props}
    style={{
      backgroundColor: 'white',
      borderColor: props.disabled ? '#5d5d5d6d' : theme.danger.hex(),
      color: 'black',
      ...props.style
    }} />
}

const CardButton = styled.button`
  span {
    font-size: 14px;
  }
`
const Card = styled.div`
  span {
    font-size: 14px;
  }
`

interface ICardWrapperButtons extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
}
interface ICardWrapper extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
}

export const CardWrapperButtons = ({ children, ...props }: ICardWrapperButtons) => {
  return <CardButton className='p-4 mx-4 bg-gray-100 rounded-lg' {...props}>
    {children}
  </CardButton>
}

export const CardWrapper = ({ children, ...props }: ICardWrapper) => {
  return <Card className='p-4 mx-4 bg-gray-100 rounded-lg' {...props}>
    {children}
  </Card>
}

interface LinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
{
  url: string
}

export const PhoneLink = ({ url, ...props }:LinkProps) => {
  return <a href={`tel:${url}`} target='_blank' rel='noreferrer' {...props}>{url}</a>
}

export const MailLink = ({ url, ...props }:LinkProps) => {
  return <a href={`mail:${url}`} target='_blank' rel='noreferrer' {...props}>{url}</a>
}

export const Drop = styled.div<{ $error?: boolean }>`
  z-index: 999;
  height: 80px;
  background-color: white;
  border: 2px ${({ $error }) => $error ? theme.danger.hex() : theme.deepBlue.hex()} dashed;
  cursor: pointer;
  p {
    margin: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`
