import { Label } from '@fluentui/react'
import PhoneInput from 'react-phone-number-input/input'
import { E164Number } from 'libphonenumber-js/types'
import { FWTextField, Stack } from '../Common/Common'
import { IClient } from '../util/commonTypes'
import { ErrorMessage } from '../Common/Error'
import React, { useCallback } from 'react'
import { IAction } from '../Common'
import { AddressInput } from '../Common/Address'

type ClientKeys = keyof IClient

interface IAddClient {
  reducer: React.Dispatch<IAction>
  optionsReducer: React.Dispatch<IAction>
  state: IClient
  errors: { [K in ClientKeys]: string }
}

export function AddClientForm ({ reducer, state, errors, optionsReducer }: IAddClient) {
  const handleChange = useCallback((path: keyof IClient) => (ev: any, value: any) => {
    if (errors[path]) optionsReducer({ type: 'remove', path: `errors.${path}` })
    reducer({ path, value })
  }, [reducer, optionsReducer, errors])

  const handlePhoneChange = useCallback((value: E164Number | undefined) => {
    if (errors.phoneNumber) optionsReducer({ type: 'remove', path: 'errors.phoneNumber' })
    reducer({ path: 'phoneNumber', value })
  }, [reducer, optionsReducer, errors.phoneNumber])

  return <Stack>
    <Stack className='p-4'>
      <FWTextField required label='Name' onChange={handleChange('name')} errorMessage={errors.name} />
      <Stack horizontal spacing={16}>
        <Stack className={errors.phoneNumber ? 'phone-input hasError' : 'phone-input'} styles={{ root: { width: '100%' } }}>
          <Label required>Phone Number</Label>
          <PhoneInput defaultCountry='US' onChange={handlePhoneChange} value={state.phoneNumber} />
          {errors.phoneNumber && <ErrorMessage>{errors.phoneNumber}</ErrorMessage>}
        </Stack>
        <FWTextField required label='Email' onChange={handleChange('email')} errorMessage={errors.email} />
      </Stack>
    </Stack>
    <AddressInput key='client-address-input' address={state.address} handleChange={handleChange('address')} error={errors.address} />
    <div className='p-4'>
      <FWTextField
        rows={4}
        multiline
        label='Comments/Description'
        onChange={handleChange('description')}
        errorMessage={errors.description}
      />
    </div>
  </Stack>
}
