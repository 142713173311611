import React, { useCallback, useContext, useReducer, useState } from 'react'
import { DefaultButton, Panel, PanelType } from '@fluentui/react'
import { DateTime } from 'luxon'
import { PrimaryButton, Stack } from '../Common/Common'
import { commonReducer } from '../util/reducers'
import styled from 'styled-components'
import { RequisitionsTable } from './RequisitionsTable'
import { RequisitionSearch } from './RequisitionsSearch'
import { requisitionData } from '../mock/requisitions'
import { RequisitionsView } from './RequisitionsView'
import { Header } from '../Common/Header'
import { AddRequisitionForm } from './AddRequisitionForm'
import { APIDiscipline, APIErrors, APIRequisition, IRequisition, OrganizationType } from '../util/commonTypes'
import { IAction } from '../Common'
import { ExportIcon } from '../assets/Icons'
import { useLoaderData, useRevalidator, useSearchParams } from 'react-router-dom'
import { api } from '../util/api'
import { CLOSE_REQUISITION, CREATE_REQUISITION, DELETE_REQUISITION } from '../queries/Requisitions'
import { Toast } from '../Toast/Toast'
import { OrgContext } from '../Nav/OrgContext'
import { CandidateSubmittal } from './CandidateSubmittal'
import { NonFatalError } from '../Common/Error'

const initialSearchState = {
  status: undefined,
  states: [],
  disciplines: []
}

const initialOptions = {
  isOpen: false,
  selected: {},
  requisitions: requisitionData,
  errors: {},
  createPanel: false
}

const initialRequisition = {
  discipline: null,
  job: null
}

const FilterBar = styled(Stack)`
  
`

const Container = styled.main`
  .form {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-gap: 16px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
`

const validate = (requisition: IRequisition) => {
  const { startDate, endDate, billRate, onCall, shiftType, discipline, job } = requisition
  const errors: Partial<Record<keyof IRequisition, string>> = {}

  if (!startDate) errors.startDate = 'Start date is required'
  if (!endDate) errors.endDate = 'End date is required'
  if (!billRate) errors.billRate = 'Bill rate is required'
  if (!onCall) errors.onCall = 'On call is required'
  if (!shiftType) errors.shiftType = 'Shift type is required'
  if (!discipline) errors.discipline = 'Discipline is required'
  if (!job) errors.job = 'Job is required'

  if (startDate && endDate) {
    const start = DateTime.fromFormat(startDate, 'yyyy-MM-dd').toMillis()
    const end = DateTime.fromFormat(endDate, 'yyyy-MM-dd').toMillis()

    if (start > end) {
      errors.endDate = 'End date cannot be before start date'
    }
  }

  return errors
}

export function Requisitions () {
  const data = useLoaderData() as { requisitions: APIRequisition[], disciplines: APIDiscipline[], errors?: string[] }
  const orgType = useContext(OrgContext)

  const revalidator = useRevalidator()
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchState, searchreducer] = useReducer(commonReducer, initialSearchState)
  const [options, optionsReducer] = useReducer(commonReducer, initialOptions)
  const [requisition, requisitionReducer]:[IRequisition, React.Dispatch<IAction>] = useReducer(commonReducer, initialRequisition)
  const [req, setReq] = useState<APIRequisition | undefined>()

  console.log(searchParams)

  const handleSearchChange = useCallback((path: string, options?: { multiselect?: boolean }) => (event: any, value: any) => {
    if (options?.multiselect) {
      console.log(searchState)
      if (value.selected) searchreducer({ type: 'update', value: [...searchState[path], value.key], path })
      else searchreducer({ type: 'update', path, value: [...searchState[path]].filter(k => k !== value.key) })
    } else searchreducer({ type: 'update', value, path })
  }, [searchState])

  const handleRequisitionChange = useCallback((path: string) => (event: any, value: any) => {
    if (options?.errors[path]) {
      optionsReducer({ type: 'remove', path: `errors.${path}` })
    }
    requisitionReducer({ type: 'update', value, path })
  }, [options.errors])

  const reset = useCallback(() => {
    searchreducer({ type: 'reset', value: initialSearchState })
    requisitionReducer({ type: 'reset', value: initialRequisition })
    optionsReducer({ type: 'reset', value: initialOptions })
  }, [])

  const handleSubmit = useCallback(async () => {
    const errors = validate(requisition)
    console.log(errors)
    if (Object.values(errors).length) {
      optionsReducer({ path: 'errors', value: errors })
      return
    }
    const { job, discipline, specialty, ...req } = requisition

    const args = {
      ...req,
      discipline_id: discipline.id,
      job_id: job.id,
      hasSpecialty: specialty ? 1 : 0,
      billRate: Number(req.billRate),
      guaranteedHours: Number(req.guaranteedHours)
    }

    try {
      const res = await api.query(CREATE_REQUISITION, {
        args
      })

      const errors = res?.errors as undefined | string[]

      if (res.createRequisition?.success) {
        revalidator.revalidate()
        reset()
      } else if (res?.createRequisition?.messages?.length) {
        const errors = res.createRequisition.messages as APIErrors[]
        console.log(errors)
        errors.map(({ message }) => Toast.makeText({ message, duration: 3000, type: 'alert' }))
      } else if (errors) {
        errors.forEach(e => Toast.makeText({ message: e, type: 'alert', duration: 3000 }))
      }
    } catch (e) {
      console.log(e)
      Toast.makeText({ message: 'Unknown error occurred. Please try again', type: 'alert', duration: 3000 })
    }
  }, [requisition, revalidator, reset])

  const handleSearch = useCallback(() => {
    const params = {} as any
    if (searchState.status?.key) {
      params.status = searchState.status.key
    }

    if (searchState.states?.length) {
      params.states = searchState.states.join(',')
    }

    if (searchState.disciplines?.length) {
      params.disciplines = searchState.disciplines.join(',')
    }

    setSearchParams(params)
  }, [searchState, setSearchParams])

  const resetSearch = useCallback(() => {
    searchreducer({ type: 'reset', value: initialSearchState })
    setSearchParams({})
  }, [setSearchParams])

  const openCreatePanel = useCallback(() => {
    optionsReducer({ path: 'createPanel', value: true })
  }, [])

  const handleTableClick = useCallback((selected: APIRequisition) => {
    optionsReducer({ type: 'set', value: { isOpen: true, selected } })
  }, [])

  const dismissPanel = useCallback((ev: any) => {
    const className = ev.target.className
    const closest = ev.target.closest('button')
    if (className.includes('overlay') || closest?.className?.includes('close')) optionsReducer({ type: 'set', value: { isOpen: false, selected: {} } })
  }, [])

  const dismissCreatePanel = useCallback(() => {
    optionsReducer({ type: 'set', value: { createPanel: false } })
    requisitionReducer({ type: 'reset', value: initialRequisition })
  }, [])

  const handleDelete = useCallback(async (id: string) => {
    try {
      const res = await api.query(DELETE_REQUISITION, { id })
      if (res?.deleteRequisition?.success) {
        revalidator.revalidate()
        Toast.makeText({ message: 'Successfully deleted', duration: 3000 })
      }
    } catch (e) {
      console.log(e)
    }
  }, [revalidator])

  const closeReq = useCallback(async (id: string) => {
    try {
      const res = await api.query(CLOSE_REQUISITION, { id })
      if (res?.closeRequisition?.success) {
        revalidator.revalidate()
        Toast.makeText({ message: 'Successfully deleted', duration: 3000, type: 'normal' })
      }
    } catch (e) {
      console.log(e)
    }
  }, [revalidator])

  const handleClose = useCallback(() => {
    setReq(undefined)
  }, [])

  const onRenderFooterContent = useCallback(() => {
    return <Stack horizontal spacing={16} horizontalAlign='end'>
      <DefaultButton text='Cancel' onClick={dismissCreatePanel} />
      <PrimaryButton text='Submit' onClick={handleSubmit} />
    </Stack>
  }, [dismissCreatePanel, handleSubmit])

  if (data.errors?.length) return <NonFatalError errors={data.errors} />

  return <Container>
    <Header>
      <h1 className='text-4xl text-center m-0'>Requisitions</h1>
      {orgType === OrganizationType.CLIENT && <PrimaryButton text='Add Requisition' onClick={openCreatePanel} />}
    </Header>
    <Stack className='bg-gray-100 p-8 pt-0'>
      <RequisitionSearch requisitions={data.requisitions} disciplines={data.disciplines} handleChange={handleSearchChange} state={searchState} />
      <Stack horizontal horizontalAlign='center' spacing={16}>
        <PrimaryButton text='Search' onClick={handleSearch} />
        <DefaultButton text='Reset' onClick={resetSearch} />
      </Stack>
    </Stack>
    <FilterBar horizontal spacing={8} horizontalAlign='end' className='p-4'>
      <DefaultButton text='Export' onRenderIcon={() => <ExportIcon size={25} />}/>
    </FilterBar>
    <RequisitionsTable data={data.requisitions} handleClick={handleTableClick} setReq={setReq} closeReq={closeReq} handleDelete={handleDelete} />
    <Panel
      isOpen={options.isOpen}
      type={PanelType.smallFixedFar}
      onDismiss={dismissPanel}
      isLightDismiss
      onRenderHeader={() => options?.selected?.requisition && <h1 className='w-full'>Requisition: {options.selected.requisition}</h1>}
    >
      <RequisitionsView data={options.selected} setReq={setReq} closeReq={closeReq} handleDelete={handleDelete} setPanel={handleTableClick} />
    </Panel>
    <Panel
      isLightDismiss
      isFooterAtBottom
      customWidth='500px'
      type={PanelType.customNear}
      isOpen={options.createPanel}
      onDismiss={dismissCreatePanel}
      onRenderHeader={() => <h1 className='w-full ml-9'>Create Requisition</h1>}
      onRenderFooterContent={onRenderFooterContent}
    >
      <AddRequisitionForm disciplines={data.disciplines} handleChange={handleRequisitionChange} state={requisition} errors={options.errors} />
    </Panel>
    {req && <CandidateSubmittal requisition={req} handleClose={handleClose} />}
  </Container >
}
