import { Label, Modal, Toggle } from '@fluentui/react'
import { FWTextField, PrimaryButton, Stack } from '../Common/Common'
import { APIUser } from '../util/commonTypes'
import { useCallback, useReducer } from 'react'
import { E164Number } from 'libphonenumber-js/types'
import PhoneInput from 'react-phone-number-input/input'
import { ErrorMessage } from '../Common/Error'
import { theme } from '../util/theme'
import { commonReducer } from '../Common'
import { InfoIcon } from '../assets/Icons'

interface IEditUser {
  currentUser: APIUser
  user: APIUser
  handleChange: (path: string) => (evt: any, value: any) => void
  errors: Partial<APIUser>
  edit: boolean
}

export function EditUserView ({ currentUser, user, handleChange, edit, errors = {} }: IEditUser) {
  const [{ isOpen, warning }, reducer] = useReducer(commonReducer, {})

  const handlePhoneChange = useCallback((value: E164Number | undefined) => {
    handleChange('phoneNumber')(null, value)
  }, [handleChange])

  const toggleModal = useCallback(() => {
    reducer({ type: 'reset', value: { isOpen: !isOpen } })
  }, [isOpen])

  const toggleAdminModal = useCallback(() => {
    reducer({ type: 'set', value: { isOpen: true, warning: 'This rule allows the user to update and delete clients and partners for MLee Healthcare VMS.' } })
  }, [])

  const toggleOrgAdmin = useCallback(() => {
    reducer({ type: 'set', value: { isOpen: true, warning: 'This rule allows the user to create, update and delete users in your organization.' } })
  }, [])

  return <>
    <Stack>
      <div className="p-4 pb-8">
        <FWTextField label='Full Name' required value={user.fullName} onChange={handleChange('fullName')} />
        {edit && <FWTextField label='Username' required value={user.userName} onChange={handleChange('userName')} />}
        <Stack className={errors.phoneNumber ? 'phone-input hasError' : 'phone-input'} styles={{ root: { width: '100%' } }}>
          <Label>Phone Number</Label>
          <PhoneInput defaultCountry='US' onChange={handlePhoneChange} value={user.phoneNumber} />
          {errors.phoneNumber && <ErrorMessage>{errors.phoneNumber}</ErrorMessage>}
        </Stack>
        <FWTextField required label='Email' type='email' value={user.email} onChange={handleChange('email')} errorMessage={errors.email} />
        <Stack className='mt-8'>
          {(edit && currentUser.admin) && <Toggle
            className='flex items-center gap-8'
            label={<Stack horizontal verticalAlign='center' spacing={2}>
              <button className='clear-btn' onClick={toggleAdminModal}><InfoIcon size={18} color={theme.blue.hex()} /></button>
              <span>Admin</span>
            </Stack>}
            onChange={handleChange('admin')}
            checked={user.admin}
            styles={{
              pill: {
                backgroundColor: user.admin ? `${theme.blue.hex()} !important` : 'transparent !important'
              }
            }}
          />}
          {(edit && (currentUser.orgAdmin || !currentUser.admin)) && <Toggle
            className='flex items-center gap-8'
            label={<Stack horizontal verticalAlign='center' spacing={2}>
              <button className='clear-btn' onClick={toggleOrgAdmin}><InfoIcon size={18} color={theme.blue.hex()} /></button>
              <span>{currentUser.admin ? 'Org Admin' : 'Admin'}</span>
            </Stack>}
            onChange={handleChange('orgAdmin')}
            checked={user.orgAdmin}
            styles={{
              pill: {
                backgroundColor: user.orgAdmin ? `${theme.blue.hex()} !important` : ''
              }
            }}
          />}
          <Toggle
            className='flex items-center gap-8'
            // label={<Stack horizontal verticalAlign='center' spacing={2}>
            //   <button className='clear-btn' onClick={toggleOrgAdmin}><InfoIcon size={18} color={theme.blue.hex()} /></button>
            //   <span>{currentUser.admin ? 'Org Admin' : 'Admin'}</span>
            // </Stack>}
            label='Contract Signer'
            onChange={handleChange('contractSigner')}
            checked={user.contractSigner}
            styles={{
              pill: {
                backgroundColor: user.contractSigner ? `${theme.blue.hex()} !important` : ''
              }
            }}
          />
        </Stack>
      </div>
    </Stack>
    {isOpen && <Modal
      isOpen={isOpen}
      onDismiss={toggleModal}
      styles={{
        main: { padding: '24px', maxWidth: 400, display: 'flex', alignItems: 'center' }
      }}
    >
      <Stack spacing={16}>
        <p className='text-2xl'>{warning}</p>
        <PrimaryButton onClick={toggleModal}>
          Close
        </PrimaryButton>
      </Stack>
    </Modal>}
  </>
}
