import React, { useCallback, useMemo, useState } from 'react'
import { Label } from '@fluentui/react'
import PhoneInput from 'react-phone-number-input/input'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { Drop, FWTextField, Stack } from '../Common/Common'
import { E164Number } from 'libphonenumber-js/types'
import { APIDiscipline, ICandidate } from '../util/commonTypes'
import { ErrorMessage } from '../Common/Error'
import { AddressInput } from '../Common/Address'
import { Select } from '../Common/Select'
import { api } from '../util/api'
import { GET_JOBS } from '../queries/Requisitions'

interface ICandidateForm {
  state: ICandidate
  handleChange: (path: keyof ICandidate, options?: { multiselect?: boolean; }) => (event: any, value: any) => void
  errors?: { [key: string]: string }
  disciplines: APIDiscipline[]
}

export function AddCandidateForm ({ state, handleChange, errors = {}, disciplines }: ICandidateForm) {
  const [jobs, setJobs] = useState([] as any[])

  // const classOptions: IComboBoxOption[] = classMockData.map(c => ({ key: c, text: c }))

  // const jobOptions: IComboBoxOption[] = useMemo(() => [
  //   { key: 'selectAll', text: 'Select All', itemType: SelectableOptionMenuItemType.SelectAll },
  //   ...jobTitleMockData.map(([id, job]) => ({ key: `${job}`, text: `${job}` }))
  // ], [])

  // const selectAllState = useMemo(() => state?.job?.length === jobOptions.length, [jobOptions.length, state?.job?.length])

  const handleDateChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const changeFunc = handleChange('dob')
    changeFunc(ev, ev.target.value)
  }, [handleChange])

  const handlePhoneChange = useCallback((value: E164Number | undefined) => {
    handleChange('mobileNumber')(null, value)
  }, [handleChange])

  const fetchJobs = useCallback(async (id: number) => {
    const { jobs } = await api.query(GET_JOBS, { id })
    setJobs(jobs)
  }, [])

  const handleDiscipline = useCallback(async (newValue: { value: string, label: string, id: number }) => {
    if (newValue?.id) {
      await fetchJobs(newValue.id)
    }
    if (state.jobs) {
      handleChange('jobs')(null, null)
    }
    handleChange('discipline')(null, newValue)
  }, [fetchJobs, handleChange, state.jobs])

  const handleSelect = useCallback((path: keyof ICandidate) => {
    return (newValue: any) => {
      handleChange(path)(null, newValue)
    }
  }, [handleChange])

  const onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (!acceptedFiles.length || acceptedFiles.length > 1 || rejectedFiles.length) return

    const file = acceptedFiles[0]
    handleChange('resume')(undefined, file)
  }, [handleChange])

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, isDragReject, fileRejections } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
      'application/pdf': ['.pdf']
    }
  })

  const fileNames = useMemo(() => acceptedFiles.map((file) => file.name), [acceptedFiles])
  const today = new Date().toISOString().split('T')[0]

  const specialties = useMemo(() => jobs.filter(j => state.jobs?.find(a => j.job === a.value)
  ).map(s => ({ value: s.specialty, label: s.specialty, id: s.id, specialty: s.specialty })).filter(s => s.specialty), [jobs, state.jobs])

  return <div>
    <Stack horizontal spacing={16} className='p-4'>
      <FWTextField required label='First Name' onChange={handleChange('firstName')} value={state.firstName} errorMessage={errors.firstName} />
      <FWTextField required label='Last Name' onChange={handleChange('lastName')} value={state.lastName} errorMessage={errors.lastName} />
    </Stack>
    <Stack horizontal spacing={16} className='p-4 bg-gray-100'>
      <Stack styles={{ root: { width: '100%' } }}>
        <Label required htmlFor='dob'>Date of birth</Label>
        <input className={errors.dob ? 'hasError' : ''} type="date" id="dob" name="dob" onChange={handleDateChange} value={state.dob} max={today} />
        {errors.dob && <ErrorMessage>{errors.dob}</ErrorMessage>}
      </Stack>
      <FWTextField label='Medical License #' onChange={handleChange('medicalLicense')} value={state.medicalLicense} />
      <FWTextField label='NPI #' onChange={handleChange('npi')} value={state.npi} />
    </Stack>
    <Stack horizontal spacing={16} className='p-4'>
      <FWTextField required label='Email' onChange={handleChange('email')} value={state.email} errorMessage={errors.email} />
      <Stack className={errors.mobileNumber ? 'phone-input hasError' : 'phone-input'} styles={{ root: { width: '100%' } }}>
        <Label required>Mobile Phone</Label>
        <PhoneInput defaultCountry='US' onChange={handlePhoneChange} value={state.mobileNumber} />
        {errors.mobileNumber && <ErrorMessage>{errors.mobileNumber}</ErrorMessage>}
      </Stack>
    </Stack>
    <AddressInput address={state.address} handleChange={handleChange('address')} error={errors.address} />
    <div className='p-4'>
      <Label required>Resume</Label>
      <Drop {...getRootProps()} $error={!!errors.resume || isDragReject || fileNames.length > 1 || !!fileRejections.length}>
        <input {...getInputProps()} />
        {
          fileRejections.length
            ? <p>File type not accepted</p>
            : fileNames.length > 1
              ? <p>Only 1 file is allowed</p>
              : fileNames.length
                ? <p>{fileNames.join(', ')}</p>
                : isDragActive
                  ? <p>Drop the file here ...</p>
                  : <p>Drag and drop file here or click to select file<br />(Only 1 file accepted)<br />.pdf .jpeg .jpg .png</p>
        }
      </Drop>
    </div>
    <Stack className='p-4 bg-gray-100'>
      <Label required>Discipline</Label>
      <Select
        required
        options={disciplines.map(c => ({ value: c.discipline, label: c.discipline, id: c.id }))}
        placeholder=''
        value={state.discipline}
        onChange={handleDiscipline as any}
        error={errors.discipline}
      />
      {errors.discipline && <ErrorMessage>{errors.discipline}</ErrorMessage>}
    </Stack>
    {state.discipline && <Stack className="p-4 slide-in">
      <Label required>Job Title</Label>
      <Select
        isMulti
        placeholder=''
        options={jobs.map(s => ({ value: s.job, label: s.job, id: s.id, specialty: s.job }))}
        onChange={handleSelect('jobs') as any}
        value={state.jobs}
        error={errors.job}
      />
      {(state.discipline && errors.job) && <ErrorMessage>{errors.job}</ErrorMessage>}
    </Stack>}
    {(state.jobs?.length && specialties?.length)
      ? <Stack className="p-4 slide-in bg-gray-100">
        <Label>Specialty</Label>
        <Select
          isMulti
          placeholder=''
          options={specialties}
          onChange={handleSelect('specialty')}
          value={state.specialty}
          error={errors.specialty}
        />
        {(state.discipline && state.jobs?.length && errors.discipline) && <ErrorMessage>{errors.specialty}</ErrorMessage>}
      </Stack>
      : null}
  </div>
}
