import React from 'react'
import { Dropdown, ComboBox, IDropdownOption, IComboBoxOption } from '@fluentui/react'
import { APIDiscipline, APIRequisition } from '../util/commonTypes'
import { states } from '../util/states'
import { Stack } from '../Common/Common'
import { useWindowSize } from '../hooks/resize'

interface IRequisitionSearch {
  handleChange: (name: string, options?: { multiselect?: boolean }) => (ev: any, value: any) => void
  state: any
  requisitions: APIRequisition[]
  disciplines: APIDiscipline[]
}

export const RequisitionSearch: React.FC<IRequisitionSearch> = ({ handleChange, state, disciplines }) => {
  const [width] = useWindowSize()
  const statusOptions: IDropdownOption[] = [{ key: '', text: 'Select a type', hidden: true }, { key: 'open', text: 'Open' }, { key: 'inprogress', text: 'In Progress' }, { key: 'closed', text: 'Closed' }]
  const stateOptions: IComboBoxOption[] = states.arrayOf().map(s => ({ key: s, text: s + ' ' }))
  const disciplineOptions: IComboBoxOption[] = disciplines?.map(d => ({ key: d.id, text: d.discipline }))

  return <Stack className='bg-gray-100 p-0 py-4 sm:p-8' horizontal wrap={width < 640} spacing={16}>
  <Dropdown
    selectedKey={state.status ? state.status.key : ''}
    onChange={handleChange('status')}
    placeholder='Select an status'
    options={statusOptions}
    styles={{ root: { width: '100%' } }}
  />
  <ComboBox
    multiSelect
    selectedKey={state.states}
    placeholder='Select a state'
    options={stateOptions}
    onChange={handleChange('states', { multiselect: true })}
    styles={{ container: { width: '100%' }, optionsContainer: { width: '100px', maxHeight: '320px' } }}
  />
  <ComboBox
    multiSelect
    selectedKey={state.disciplines}
    placeholder='Select a discipline'
    options={disciplineOptions}
    onChange={handleChange('disciplines', { multiselect: true })}
    styles={{ container: { width: '100%' }, optionsContainer: { width: '100px', maxHeight: '320px' } }}
  />
</Stack>
}
