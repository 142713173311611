import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { MailLink, PhoneLink, PrimaryButton, Stack } from '../Common/Common'
import { StatusPill } from './RequisitionsTable'
import { APIRequisition, OrganizationType } from '../util/commonTypes'
import { formatDate } from '../util/helpers'
import { OrgContext } from '../Nav/OrgContext'
import { formatPhoneNumber } from 'react-phone-number-input'
import { DefaultButton, IconButton } from '@fluentui/react'
import { ThreeDotsVerticalIcon } from '../assets/Icons'
import { api } from '../util/api'
import { ACCEPT_CANDIDATE } from '../queries/Requisitions'
import { useRevalidator } from 'react-router-dom'
import { Toast } from '../Toast/Toast'

interface IRequisitionView {
  closeReq: (id: string) => Promise<void>
  handleDelete: (id: string) => Promise<void>
  setReq: (req: APIRequisition) => void
  data: APIRequisition
  setPanel: (req: APIRequisition) => void
}

const Container = styled(Stack)``

const Label = styled.span`
  font-weight: 600;
`

export const RequisitionsView = ({ data, closeReq, handleDelete, setReq, setPanel }: IRequisitionView) => {
  const orgType = useContext(OrgContext)
  const revalidator = useRevalidator()

  const acceptCandidate = useCallback(async (candidateId: string) => {
    const res = await api.query(ACCEPT_CANDIDATE, {
      candidateId,
      requisitionId: data.id
    })

    const errors = res?.errors as string[]

    if (res?.acceptCandidate?.success) {
      revalidator.revalidate()
      setPanel(res.acceptCandidate.requisition)
    } else if (errors?.length) {
      errors.forEach(e => Toast.makeText({ message: e, duration: 3000 }))
    }
  }, [data.id, revalidator, setPanel])

  const candidateItems = useCallback((id: string) => {
    const items = []
    if (orgType === OrganizationType.CLIENT) items.push({ key: 'accept', text: 'Accept', onClick: () => { acceptCandidate(id) } })
    return items
  }, [orgType, acceptCandidate])

  if (!data) return null
  const clientClosed = orgType === OrganizationType.CLIENT && data.status !== 'CLOSED'
  return <Container spacing={16}>
    <Stack horizontal verticalAlign='center' horizontalAlign='space-between' className={`p-4 ${clientClosed ? 'px-0' : ''}`}>
      <Stack horizontal verticalAlign='center' spacing={8}>
        {clientClosed && <IconButton
          menuProps={{
            items: [
              { key: 'close', text: 'Close', onClick: () => { closeReq(data.id) } },
              { key: 'delete', text: 'Delete', onClick: () => { handleDelete(data.id) } }
            ]
          }}
          onRenderIcon={() => <ThreeDotsVerticalIcon />}
          ariaLabel="More options"
          styles={{ menuIcon: { display: 'none' } }}
        />}
        <Stack>
          <Label>Bill Rate: <b className='text-2xl'>${data.billRate}</b></Label>
          {data.guaranteedHours && <Label>Guaranteed Hours: {data.guaranteedHours}</Label>}
        </Stack>
      </Stack>
      <StatusPill status={data.status} size='med' />
    </Stack>
    <Stack horizontal spacing={8} verticalAlign='center' horizontalAlign='space-between' className='p-4 bg-gray-100' wrap>
      <Stack>
        <Label>Discipline:</Label>
        <span>{data.discipline}</span>
      </Stack>
      <Stack horizontalAlign='end'>
        <Label>Job:</Label>
        <span>{data.job}</span>
      </Stack>
      {data.specialty && <Stack>
        <Label>Specialty:</Label>
        <span>{data.specialty}</span>
      </Stack>}
    </Stack>
    <Stack horizontal horizontalAlign='space-between' className='p-4'>
      <Stack>
        <Label>Date Period:</Label>
        {formatDate(data.startDate)} - {formatDate(data.endDate)}
      </Stack>
      <Stack horizontalAlign='end'>
        <Label>Region:</Label>
        {data.city}, {data.state}
      </Stack>
    </Stack>
    <Stack horizontal horizontalAlign='space-between' className='p-4 bg-gray-100'>
      <Stack>
        <Label>Shift Type:</Label>
        {data.shiftType?.toUpperCase()}
      </Stack>
      <Stack horizontalAlign='end'>
        <Label>On Call:</Label>
        {data.onCall?.toUpperCase()}
      </Stack>
    </Stack>
    {data.comments && <Stack className='p-4'>
      <Label>Comments/Description:</Label> {data.comments}
    </Stack>}

    {data.candidates?.length
      ? <Stack spacing={8}>
        <Label>{data.status === 'CLOSED' && data.candidates[0].accepted ? 'Accepted Candidate' : 'Submitted Candidates'}</Label>
        {data.candidates.map((c, k) => <Stack key={k} spacing={10} className={`border-2 p-2 rounded ${c.accepted ? 'border-blue-200' : ''}`}>
          <Stack horizontal horizontalAlign='space-between'>
            <a href={`/candidates/${c.id}`} target='_blank' rel='noreferrer' className='underline'>{c.name}</a>
            {data.status !== 'CLOSED' && <DefaultButton
              menuProps={{
                items: candidateItems(c.id)
              }}
              className='clear-btn p-0'
              styles={{ menuIcon: { display: 'none' } }}
              style={{ border: 'none' }}
            >
              <ThreeDotsVerticalIcon />
            </DefaultButton>}
          </Stack>
          <Stack horizontal horizontalAlign='space-between'>
            <PhoneLink url={formatPhoneNumber(c.mobileNumber)} />
            <MailLink url={c.email} />
          </Stack>
        </Stack>)}
      </Stack>
      : null}

    <Stack>
      {(orgType !== OrganizationType.CLIENT && data.status !== 'CLOSED') && <PrimaryButton
        className='mt-8'
        onClick={() => setReq(data)}
      >
        Submit Candidate
      </PrimaryButton>}
    </Stack>
  </Container>
}
