import { useCallback } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { formatPhoneNumber } from 'react-phone-number-input'
import { Body, Header as TableHeader, HeaderCell, Row, RowCell, Table } from '../Common/Table'
import { APIPartner, Status } from '../util/commonTypes'
import { Header } from '../Common/Header'
import { StarIcon } from '../assets/Icons'
import { EmptyState } from '../NotFound/NotFound'
import { NonFatalError } from '../Common/Error'

interface IPartnersTable {
  data: APIPartner[]
  handleClick: (value: any) => void
  handleEdit: (value: any) => void
}

export function Partners () {
  const data: any = useLoaderData()
  const partners: APIPartner[] = data?.partners

  const navigate = useNavigate()

  const handleClick = useCallback((row: APIPartner) => {
    navigate(`${row.id}`)
  }, [navigate])

  const handleEdit = useCallback(() => {

  }, [])

  if (data?.errors) return <NonFatalError errors={data.errors} />

  return <main>
    <Header>
      <h1 className='text-4xl text-center m-0'>Partners</h1>
    </Header>
    {partners?.length ? <PartnersTable data={partners} handleClick={handleClick} handleEdit={handleEdit} /> : <EmptyState text='No partners yet' />}
  </main>
}

function PartnerStatus ({ status }:{ status: Status }) {
  const partnerStatus = {
    [Status.NEW]: <StarIcon />,
    [Status.APPROVED]: null,
    [Status.DENIED]: null,
    [Status.VIEWED]: null
  }
  return partnerStatus[status]
}

function PartnersTable ({ data, handleClick }: IPartnersTable) {
  return <Table id='partner-table' classNames={{ table: 'table' }}>
    <TableHeader>
      <HeaderCell>Name</HeaderCell>
      <HeaderCell>Job Title</HeaderCell>
      <HeaderCell>Organization</HeaderCell>
      <HeaderCell>Phone Number</HeaderCell>
      <HeaderCell>Email</HeaderCell>
      <HeaderCell></HeaderCell>
    </TableHeader>
    <Body>
      {data.map((row, key) => <Row key={key}>
          <RowCell onClick={() => handleClick(row)}>
            <PartnerStatus status={row.status} />
            &nbsp;
            {row.fullName}
          </RowCell>
          <RowCell onClick={() => handleClick(row)}>{row.jobTitle}</RowCell>
          <RowCell onClick={() => handleClick(row)}>{row.organization}</RowCell>
          <RowCell onClick={() => handleClick(row)}>{formatPhoneNumber(row.phoneNumber)}</RowCell>
          <RowCell onClick={() => handleClick(row)}>{row.email}</RowCell>
          <RowCell></RowCell>
        </Row>
      )}
    </Body>
  </Table>
}
