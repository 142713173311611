import React, { useCallback } from 'react'
import { IAction } from '../Common'
import { FWTextField, PrimaryButton, Stack } from '../Common/Common'
import { IExperience } from '../util/commonTypes'
import { CloseIcon } from '../assets/Icons'
import { Label } from '@fluentui/react'

interface IAddExperience {
  optionsReducer: React.Dispatch<IAction>
  experienceList: IExperience[]
}

interface IExperienceForm {
  index: number
  optionsReducer: React.Dispatch<IAction>
  remove: (index: number) => void
  experience: IExperience
}

const initialExperience: IExperience = {
  employer: '',
  endDate: '',
  jobTitle: '',
  startDate: ''
}

function ExperienceForm ({ index, optionsReducer, remove, experience }: IExperienceForm) {
  const handleDateChange = useCallback((path: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    optionsReducer({ path: `experience[${index}].${path}`, value: ev.target.value })
  }, [optionsReducer, index])

  const update = useCallback((path: string) => (ev: any, value: string | undefined) => {
    optionsReducer({ path: `experience[${index}].${path}`, value })
  }, [optionsReducer, index])

  return <Stack className='bg-gray-100 p-4'>
    <Stack horizontalAlign='end' style={{ marginBottom: -20 }}>
      <button onClick={() => remove(index)}><CloseIcon /></button>
    </Stack>
    <Stack horizontal spacing={16} className='mt-4'>
      <FWTextField required value={experience.employer} onChange={update('employer')} label='Employer' />
      <FWTextField required value={experience.jobTitle} onChange={update('jobTitle')} label='Job Title' />
    </Stack>
    <Stack horizontal spacing={16}>
      <Stack styles={{ root: { width: '100%' } }}>
        <Label required htmlFor='startDate'>Start Date</Label>
        <input
        // className={errors.dob ? 'hasError' : ''}
        type="date"
        id="startDate"
        name="startDate"
        onChange={handleDateChange('startDate')}
        value={experience.startDate} />
        {/* {errors.dob && <ErrorMessage>{errors.dob}</ErrorMessage>} */}
      </Stack>
      <Stack styles={{ root: { width: '100%' } }}>
        <Label required htmlFor='endDate'>End Date</Label>
        <input
        // className={errors.dob ? 'hasError' : ''}
        type="date"
        id="endDate"
        name="endDate"
        onChange={handleDateChange('endDate')}
        value={experience.endDate} />
        {/* {errors.dob && <ErrorMessage>{errors.dob}</ErrorMessage>} */}
      </Stack>
    </Stack>
  </Stack>
}

export function AddExperienceForm ({ optionsReducer, experienceList }: IAddExperience) {
  const addExperience = useCallback(() => {
    optionsReducer({ path: 'experience', value: [...experienceList, Object.assign({}, initialExperience)] })
  }, [optionsReducer, experienceList])

  const deleteExperience = useCallback((index: number) => {
    const newArray = [...experienceList]
    newArray.splice(index, 1)
    optionsReducer({ path: 'experience', value: newArray })
  }, [optionsReducer, experienceList])

  return <Stack className='mt-5' spacing={16}>
    {experienceList.map((e, i) => <ExperienceForm key={i} index={i} optionsReducer={optionsReducer} remove={deleteExperience} experience={e} />)}
    <PrimaryButton text='Add Experience' onClick={addExperience} />
  </Stack>
}
