import React from 'react'
import { Link } from 'react-router-dom'
import { Stack } from '../Common/Common'

interface INotFound {
  code?: string
  message?: string
  description?: string
  href?: string
  linkText?: string
}

export function NotFound ({ message, description, code, href, linkText }: INotFound) {
  return <main className='p-0'>
    <Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { height: '100vh' } }}>
      <p className='text-base font-semibold text-blue-900 sm:text-5xl'>{code || '404'}</p>
      <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
        {message || 'Page not found'}
      </h1>
      <p className='mt-6 text-base leading-7 text-gray- text-xl'>
        {description || "Sorry, we couldn't find the page you're looking for."}
      </p>
      <div className='mt-10 flex items-center justify-center gap-x-6'>
        <Link to={href || '/'} className='rounded-sm bg-blue-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-200 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900'>{linkText || 'Go back home'}</Link>
      </div>
    </Stack>
  </main>
}

export function EmptyState ({ text, children }:{ text: string, children?: React.ReactNode}) {
  return <Stack verticalFill horizontalAlign='center' verticalAlign='center' spacing={40} className='mt-8'>
  {text && <h2 className='text-3xl sm:text-4x text-center'>{text}</h2>}
  <img src='/box.png' alt='empty box' style={{ width: 150 }} />
  {children}
</Stack>
}
