import React, { FormEvent, useCallback, useEffect, useReducer } from 'react'
import { FWTextField, PrimaryButton, Stack } from '../Common/Common'
import { commonReducer } from '../Common'
import { Link, useNavigate } from 'react-router-dom'
import { Spinner } from '@fluentui/react'
import { theme } from '../util/theme'
import { UserNoOutlineIcon } from '../assets/Icons'
import { GET_USERS } from '../queries/Users'

const initialOptions = {
  email: '',
  password: '',
  error: false,
  loading: false,
  pageLoading: true
}

export function Login () {
  const [{ email, password, error, loading, pageLoading }, reducer] = useReducer(commonReducer, initialOptions)

  const navigate = useNavigate()

  const fetchUser = useCallback(async () => {
    const res = await fetch(process.env.REACT_APP_API_BASE_URL + '', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: GET_USERS
      })
    })

    if (res.status === 200) {
      navigate('/')
    } else {
      sessionStorage.setItem('mlee-vms-permissions', '')
      reducer({ path: 'pageLoading', value: false })
    }
  }, [navigate])

  const handleChange = useCallback((path: string) => (ev: any, value: string | undefined) => {
    reducer({ path, value })
  }, [])

  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    reducer({ path: 'loading', value: true })

    const res = await fetch(process.env.REACT_APP_API_BASE_URL + '/login', {
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({ email, password })
    })

    if (res.status !== 200) {
      reducer({
        type: 'set',
        value: {
          error: true,
          loading: false
        }
      })
    } else {
      navigate('/')
    }
  }, [email, password, navigate])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return <main className='p-0 h-screen'>
    {pageLoading
      ? <Stack
        verticalAlign='center'
        horizontalAlign='center'
        verticalFill
        className='sm:p-16 p-8'
      >
        <Spinner styles={{ circle: { width: 80, height: 80, borderWidth: 6 } }} />
      </Stack>
      : <Stack
      verticalAlign='center'
      horizontalAlign='center'
      verticalFill
      className='sm:p-16 p-8'
    >
      <form onSubmit={handleSubmit} className='relative sm:w-1/2 w-full flex flex-col justify-center'>
      <Stack horizontalAlign='center'>
        <div style={{ borderRadius: 500, border: ` 3px solid ${theme.blue.hex()}`, padding: 20 }}>
         <UserNoOutlineIcon size={64} color={theme.blue.hex()} />
        </div>
      </Stack>
        <h1 className='text-blue-900 text-center font-semibold'>Sign in to your account</h1>
        <FWTextField required label="Username/Email Address" onChange={handleChange('email')} autoComplete='email'/>
        <FWTextField required canRevealPassword label="Password" type='password' onChange={handleChange('password')} />
        {error && <p className='m-0 text-sm text-red-900 slide-in' style={{ position: 'absolute', bottom: 50 }}>Invalid email and/or password</p>}
        <Link to='/password-reset' className='text-blue-900 mt-4 text-end'>Forgot Password?</Link>
        <PrimaryButton
          type='submit'
          className='mt-4'
          disabled={loading}
          onRenderText={() => loading ? <Spinner key='spinner' className='fade-in' /> : <span key='sign-in'>Sign In</span>} />
      </form>
      <p className='py-4'>Not a partner? <Link to='/become-partner' className='text-blue-900'>Apply to be a partner</Link></p>
    </Stack>}
  </main>
}
