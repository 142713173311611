import './toast.css'

const container = document.createElement('div')
container.classList.add('toasty-container')
document.body.appendChild(container)

export enum ToastGravity {
  Right='toasty-right'
}

interface MakeText {
  message: string
  type?: 'alert' | 'normal' | undefined
  duration?: number
  gravity?: ToastGravity
}

function createRandomID () {
  const randLetter1 = String.fromCharCode(65 + Math.floor(Math.random() * 26))
  const randLetter2 = String.fromCharCode(65 + Math.floor(Math.random() * 26))
  return randLetter1 + Date.now() + randLetter2
}

export const Toast: { makeText: (args:MakeText) => void} = {
  makeText ({ message, type = 'alert', duration, gravity }) {
    new TOAST({ message, duration, type, gravity }).show()
  }
}

interface IToast extends HTMLDivElement {
  id: string
}

class TOAST {
  message: string
  type: 'alert' | 'normal' | undefined
  duration?: number
  gravity?: string
  _toast: IToast
  _container: HTMLDivElement
  _innerContainer: HTMLDivElement

  constructor ({ message, gravity, duration, type }: MakeText) {
    this.message = message
    this.type = type
    this.duration = duration
    this.gravity = gravity
    this._toast = document.createElement('div')
    this._innerContainer = document.createElement('div')
    this._container = container
    this._toast.id = createRandomID()
  }

  addStyles = () => {
    this._container.classList.add('toasty-container-visible')
    this._innerContainer.classList.add('inner-toasty')
    this._toast.classList.add('toasty')
    if (this.gravity) this._innerContainer.classList.add(this.gravity)
    if (this.duration) this._toast.classList.add('toast-no-button')
    if (this.type === 'alert') this._toast.classList.add('alert')
  }

  addAria = () => {
    this._container.setAttribute('aria-live', 'polite')
  }

  addMessage = () => {
    const el = document.createElement('span')
    const button = document.createElement('button')
    const text = document.createTextNode(this.message)

    button.classList.add('close-toast', 'clear-btn')
    button.onclick = this.expireToast
    button.appendChild(document.createTextNode('X'))

    el.appendChild(text)

    this._toast.appendChild(el)
    if (!this.duration) this._toast.appendChild(button)
    this._container.appendChild(this._toast)
  }

  addToastToContainer = () => {
    this._innerContainer.appendChild(this._toast)
    this._container.appendChild(this._innerContainer)
    this._container.focus()
    if (this.duration) {
      const timeout = setTimeout(() => {
        this.expireToast()
        clearTimeout(timeout)
      }, this.duration)
    }
  }

  removeToastFromBody = () => {
    this._container.removeChild(this._innerContainer)
  }

  expireToast = () => {
    this.removeToastFromBody()
    if (this._container.childElementCount < 1) this._container.classList.remove('toasty-container-visible')
  }

  show = () => {
    this.addStyles()
    this.addAria()
    this.addMessage()
    this.addToastToContainer()
  }
}
