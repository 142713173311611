import React, { useMemo } from 'react'
import { EmailIcon, LocationIcon, PhoneIcon } from '../assets/Icons'
import { IAddress } from '../util/commonTypes'
import { theme } from '../util/theme'
import { RowCell, RowCellProps } from './Table'
import { TooltipButton } from './TooltipButton'
import { formatPhoneNumber } from 'react-phone-number-input'

interface ICalloutButtons {
  address?: IAddress
  phoneNumber?: string
  email?: string
  rowCellProps?: RowCellProps
  children?: React.ReactNode
}

export function TableCalloutButtons ({ address, phoneNumber, email, rowCellProps, children }: ICalloutButtons) {
  const addressLink = useMemo(() => address?.address
    ? <a href={`https://www.google.com/maps/search/?api=1&query=${address.address.replace(' ', '%20')}%20${address.city}%2C%20${address.state}%20${address.zipcode}`} target='_blank' rel='noreferrer' className='flex align-center justify-center rounded-full border-2 border-blue-900 p-1'>
      <LocationIcon color={theme.blue.hex()} size={20} />
    </a>
    : null, [address])

  return <RowCell className='flex justify-around' {...rowCellProps}>
    {addressLink}
    {phoneNumber && <TooltipButton name={formatPhoneNumber(phoneNumber)}>
      <a href={`tel:${phoneNumber}`} className='flex align-center justify-center rounded-full border-2 border-blue-900 p-1'>
        <PhoneIcon color={theme.blue.hex()} size={20} />
      </a>
    </TooltipButton>}
    {email && <TooltipButton name={email}>
      <a href={`mailto:${email}`} target='_blank' rel='noreferrer' className='flex align-center justify-center rounded-full border-2 border-blue-900 p-1'>
        <EmailIcon color={theme.blue.hex()} size={20} />
      </a>
    </TooltipButton>}
    {children}
  </RowCell>
}
