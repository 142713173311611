import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HomeIcon } from '../assets/Home'
import { BriefcaseIcon, LogOutIcon, StethoscopeIcon, WorldIcon } from '../assets/Icons'
import { LogoIcon } from '../assets/Logo'
import { UserIcon, UsersIcon } from '../assets/User'
import { theme } from '../util/theme'
import { Stack } from '../Common/Common'
import './Nav.css'
import { OrganizationType } from '../util/commonTypes'
import { OrgContext } from './OrgContext'

const NavLinksWrapper = styled.div<{ mobile: any, open: any }>`
  position: ${({ mobile }) => mobile ? 'absolute' : 'initial'};
  height: ${({ mobile }) => mobile ? '100vh' : '100%'};
  width: ${({ mobile }) => mobile ? '100vw' : '100%'};
  background-color: ${({ mobile }) => mobile ? theme.blue.hex() : 'transparent'};
  top: 0;
  left: 0;
  transform: ${({ open, mobile }) => open || !mobile ? 'translateY(0%)' : 'translateY(-100%)'};
  transition: all .5s;
`

export function Nav ({ authenticated, children, notFound = false }: { authenticated?: boolean, notFound?: boolean, children: React.ReactNode }) {
  const [mobile, setMobile] = useState(document.documentElement.clientWidth <= 800)
  const [isOpen, setIsOpen] = useState(false)
  const [orgType, setOrgType] = useState<OrganizationType | undefined>()

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const resizeListener = useCallback((ev: UIEvent) => {
    const w = document.documentElement.clientWidth
    if (w > 800 && mobile) setMobile(false)
    else if (w <= 800 && !mobile) setMobile(true)
  }, [mobile])

  useEffect(() => {
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [resizeListener])

  useEffect(() => {
    const orgType = sessionStorage.getItem('mlee-vms-permissions') as OrganizationType | undefined
    if (orgType) setOrgType(orgType)
  }, [])

  return <>
  <nav className='fixed w-full bg-blue-900 p-3 flex justify-between items-center z-50'>
    <Link to='/'>
      <LogoIcon />
    </Link>
    {authenticated && <NavLinks mobile={mobile} isOpen={isOpen} orgType={orgType} handleClose={handleClose} />}
    {(mobile && authenticated)
      ? <NavButton isOpen={isOpen} handleClick={handleClick} />
      : authenticated
        ? <Dropdown />
        : !notFound ? <Link to='/login' className='text-white'>Log In</Link> : null
    }
  </nav>
  <OrgContext.Provider value={orgType}>
    {children}
  </OrgContext.Provider>
  </>
}

function NavLinks ({ mobile = false, isOpen = false, orgType, handleClose }: { mobile: boolean, isOpen: boolean, orgType?: OrganizationType, handleClose: () => void }) {
  return <NavLinksWrapper mobile={mobile ? `${mobile}` : undefined} open={isOpen ? true : ''}>
    <ul className={`flex ${mobile ? 'flex-col' : 'flex-row'} justify-center`} style={{ gap: mobile ? 20 : 0, height: '100%' }}>
      <li>
        <Link onClick={handleClose} to='/' className="block lg:mt-0 text-blue-200 hover:text-white mr-4 flex flex-col items-center">
          <HomeIcon />
          <span>Dashboard</span>
        </Link>
      </li>
      <li>
        <Link onClick={handleClose} to="/requisition" className="block lg:mt-0 text-blue-200 hover:text-white mr-4 flex flex-col items-center">
          <BriefcaseIcon />
          <span>Requisition</span>
        </Link>
      </li>
      <li>
        <Link onClick={handleClose} to="/candidates" className="block lg:mt-0 text-blue-200 hover:text-white mr-4 flex flex-col items-center">
          <UsersIcon />
          <span>Candidates</span>
        </Link>
      </li>
      {orgType === OrganizationType.INTERNAL && <>
        <li>
          <Link onClick={handleClose} to="/partners" className="block lg:mt-0 text-blue-200 hover:text-white mr-4 flex flex-col items-center">
            <WorldIcon />
            <span>Partners</span>
          </Link>
        </li>
        <li>
          <Link onClick={handleClose} to="/clients" className="block lg:mt-0 text-blue-200 hover:text-white mr-4 flex flex-col items-center">
            <StethoscopeIcon className='w-6 h-6' />
            <span>Clients</span>
          </Link>
        </li>
      </>}
      {mobile &&
        <Stack horizontal horizontalAlign='center' spacing={16} className='mt-8'>
          <li>
            <Link
              id="menu-item-0"
              to='/profile'
              onClick={handleClose}
              className="block lg:mt-0 text-blue-200 hover:text-white mr-4 flex flex-col items-center">
              <UserIcon color='currentColor' className='w-6 h-6' />
              <span>Account</span>
            </Link>
          </li>
          <li className='className="block lg:mt-0 text-blue-200 hover:text-white mr-4 flex flex-col items-center'>
            <LogOutIcon />
            <a
              id="sign-out"
              href={process.env.REACT_APP_API_BASE_URL + '/logout'}
              role="menuitem"
              onClick={handleClose}
            >Sign Out</a>
          </li>
        </Stack>}
    </ul>
  </NavLinksWrapper>
}

function Dropdown () {
  const navigate = useNavigate()
  const [active, setActive] = useState(false)

  const handleClick = useCallback(() => {
    setActive(!active)
  }, [active])

  const handleLogout = async () => {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, { credentials: 'include' })

    navigate('/login')
  }

  const clickListener = useCallback((ev: MouseEvent) => {
    const target = ev?.target as any
    const classNames = target?.getAttribute('class')
    if (classNames && typeof classNames === 'string') {
      if (classNames.includes('nav-dropdown-button')) return
    } else {
      if (target.parentNode.getAttribute) {
        const parentClassNames = target.parentNode.getAttribute('class')
        if (parentClassNames && typeof parentClassNames === 'string') {
          if (parentClassNames.includes('nav-dropdown-button')) return
        }
      }
    }

    if (target?.id !== 'sign-out') return setActive(false)
  }, [])

  const keyupListener = useCallback((ev: KeyboardEvent) => {
    if (ev.key === 'Escape') setActive(false)
  }, [])

  useEffect(() => {
    window.addEventListener('click', clickListener)
    window.addEventListener('keyup', keyupListener)
    return () => {
      window.removeEventListener('click', clickListener)
      window.removeEventListener('keyup', keyupListener)
    }
  }, [clickListener, keyupListener])

  return <div className="relative inline-block text-left">
    <div>
      <button type="button" className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:text-blue-500 hover:bg-blue-800 nav-dropdown-button" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={handleClick}>
        <UserIcon color='white' className='w-6 h-6 nav-dropdown-button' />
        <svg className="-mr-1 h-5 w-5 text-gray-400 nav-dropdown-button" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" className='nav-dropdown-button' />
        </svg>
      </button>
    </div>

    {active && <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
      <div className="py-1" role="none">
        <Link
          id="menu-item-0"
          to='/profile'
          role="menuitem"
          className="text-gray-700 block px-4 py-2 text-sm"
        >Profile</Link>
        <button
          id="sign-out"
          onClick={handleLogout}
          role="menuitem"
          className="text-gray-700 block px-4 py-2 text-sm"
        >Sign Out</button>
      </div>
    </div>}
  </div>
}

function NavButton ({ isOpen, handleClick }: { handleClick: () => void, isOpen: boolean }) {
  return <button className={`menu ${isOpen ? 'open' : ''}`} onClick={handleClick}>
    <div className="bar"></div>
    <div className="bar"> </div>
  </button>
}
