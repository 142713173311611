import { createTheme, loadTheme } from '@fluentui/react'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import Color from 'color'

export const theme = {
  maroon: Color('#501214'),
  gold: Color('#6a5638'),
  charcoal: Color('#363534'),
  deepBlue: Color('#00356b'),
  lightDeepBlue: Color('#0075B4'),
  sandstone: Color('#e8e3db'),
  river: Color('#a1c1b5'),
  background: Color('#f6f6f6'),
  placeholder: Color('#dedede'),
  trueBlack: Color('#222'),
  lightGray: Color('#d8d8d8'),
  white: Color('#FFF'),
  danger: Color('#B30E1B'),
  yellow: Color('#facc15'),
  blue: Color('#00356b'),
  blueInputBorder: Color('rgb(67, 116, 166)')
}

const myTheme = createTheme({
  palette: {
    themePrimary: '#00356b',
    themeLighterAlt: '#eff4f9',
    themeLighter: '#c2d5e7',
    themeLight: '#93b3d3',
    themeTertiary: '#4374a6',
    themeSecondary: '#0f467d',
    themeDarkAlt: '#003060',
    themeDark: '#002951',
    themeDarker: '#001e3c',
    neutralLighterAlt: '#ffffff',
    neutralLighter: '#ffffff',
    neutralLight: '#ffffff',
    neutralQuaternaryAlt: '#ffffff',
    neutralQuaternary: '#ffffff',
    neutralTertiaryAlt: '#ffffff',
    neutralTertiary: '#93b3d3',
    neutralSecondary: '#4374a6',
    neutralPrimaryAlt: '#0f467d',
    neutralPrimary: '#00356b',
    neutralDark: '#002951',
    black: '#001e3c',
    white: '#ffffff'
  }
})

loadTheme(myTheme)
initializeIcons()
