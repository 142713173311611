import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Label } from '@fluentui/react'
import { IAddress } from '../util/commonTypes'
import { Stack } from './Common'
import { fillInAddress } from '../Candidates/googleHelpers'
import { ErrorMessage } from './Error'
import { EditIcon } from '../assets/Icons'

interface IAddressInput {
  address: IAddress
  handleChange: (ev: any, value: any) => void
  error?: string,
  places?: boolean
}

export function AddressInput ({ address: addressInput, handleChange, error, places }: IAddressInput) {
  const googleAddressRef = useRef<HTMLInputElement | null>(null)
  const [editAddress, setEditAddress] = useState(false)

  const toggleAddress = useCallback(() => {
    setEditAddress(!editAddress)
  }, [editAddress])

  const address = useMemo(() => {
    if (addressInput?.address) {
      return `${addressInput?.address}. ${addressInput?.city}, ${addressInput?.state} ${addressInput?.zipcode}`
    } else return ''
  }, [addressInput])

  useEffect(() => {
    if (googleAddressRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(googleAddressRef.current, {
        componentRestrictions: { country: ['us'] },
        fields: ['address_components', 'geometry', 'name'],
        strictBounds: false
      })

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete?.getPlace()

        if (!place?.geometry || !place?.geometry.location) {
          console.log('ALERT NO PLACE')
          return {}
          // return window.alert("No details available for input: '" + place.name + "'")
        }
        handleChange(undefined, fillInAddress(autocomplete))
        setEditAddress(false)
      })
    }
  }, [handleChange])

  //   <ReactGoogleAutocomplete
  //   id='address'
  //   type='text'
  //   name='address'
  //   options={{
  //     componentRestrictions: { country: ['us'] },
  //     strictBounds: false,
  //     fields: ['address_components', 'geometry', 'name']
  //   }}
  //   className={error ? 'textfield hasError' : 'textfield'}
  //   style={{ display: editAddress || !address ? 'block' : 'none' }}
  // />

  return <div className='p-4 bg-gray-100'>
    <Label required htmlFor='address'>Address</Label>
    {(address && !editAddress) && <Stack horizontal horizontalAlign='space-between'><span>{address}</span><button onClick={toggleAddress}><EditIcon size={20} /></button></Stack>}
    <input style={{ display: editAddress || !address ? 'block' : 'none' }} id='address' name='address' className={error ? 'textfield hasError' : 'textfield'} type='text' ref={googleAddressRef} />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
}
