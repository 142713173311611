export interface IAddress {
  address: string
  city: string
  state: string
  zipcode: string
}

export interface ICandidate {
  id?: string
  firstName: string
  lastName: string
  dob: string
  mobileNumber: string
  medicalLicense?: string
  npi?: string
  discipline: ISelectOption | undefined
  email: string
  address: IAddress
  jobs: ISelectOption[] | undefined
  resume?: any
  specialty?: ISelectOption[] | undefined
}

export interface IClient {
  name: string
  description: string
  phoneNumber: string
  email: string
  address: IAddress
}

export interface IExperience {
  employer: string
  jobTitle: string
  startDate: string
  endDate: string
}

export interface IEducation {
  degreeType: string
  schoolName: string
  fieldOfStudy: string
  graduationDate: string
}

export interface APICandidate {
  id: string
  firstName: string
  lastName: string
  dob: string
  mobileNumber: string
  medicalLicense?: string
  npi?: string
  discipline: string
  email: string
  address: IAddress
  jobs: string[]
  specialties: string[]
  resume: any
  resumeMimeType: string
  jobInfo?: [{
    job_id: number
    hasSpecialty: boolean
    job: string
    specialty: string
  }]
}

export interface IRequisition {
  guaranteedHours: number
  shiftType: string
  startDate: string
  endDate: string
  onCall: string
  coordinator: string
  comments: string
  status: 'OPEN' | 'INPROGRESS' | 'CLOSED'
  billRate: number
  discipline: ISelectOption
  job: ISelectOption
  specialty: ISelectOption
}

export interface APIRequisition {
  id: string
  guaranteedHours: number
  shiftType: string
  startDate: string
  endDate: string
  onCall: string
  comments: string
  status: 'OPEN' | 'INPROGRESS' | 'CLOSED'
  billRate: number
  discipline_id: string
  job_id: string
  job: string
  specialty?: string
  discipline: string
  city: string
  state: string
  candidates?: ReqCandidate[]
}

interface ReqCandidate {
  id: string
  name: string
  email: string
  mobileNumber: string
  accepted: boolean
}

export interface APIDiscipline {
  id: number
  discipline: string
}

export enum OrganizationType {
  INTERNAL = 'INTERNAL',
  PARTNER = 'PARTNER',
  CLIENT = 'CLIENT'
}

export interface APIOrganization {
  name: string
  type: OrganizationType
  description: string
}

export interface APIUser {
  id: string
  fullName: string
  userName: string
  email: string
  created: string
  phoneNumber: string
  organization: APIOrganization
  admin: boolean
  orgAdmin: boolean
  contractSigner: boolean
}

export interface APIAnalytics {
  filledRequisitions: number
  openRequisitions: number
  candidates: number
  submittedCandidates: number
}

export interface APIComment {
  comment: string
  user: string
}

export interface APIPartner {
  id: number
  fullName: string
  phoneNumber: string
  email: string
  jobTitle: string
  organization: string
  states: string
  partnerComment?: string
  status: Status
  comments?: APIComment[]
  docusignStatus: string
}

export interface APIClient {
  id: number
  name: string
  description: string
  phoneNumber: string
  email: string
  address: IAddress
  requisitions?: APIRequisition[]
}

export enum Status {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  VIEWED = 'VIEWED',
  DENIED = 'DENIED'
}

export enum RequisitionStatus {
  OPEN = 'OPEN',
  INPROGRESS = 'INPROGRESS',
  CLOSED = 'CLOSED'
}

export interface APIErrors {
  arg: string
  message: string
  type: 'error'
}

export interface ISelectOption { value: string, label: string, id: string }
