import React, { useCallback } from 'react'
import { IAction } from '../Common'
import { FWTextField, PrimaryButton, Stack } from '../Common/Common'
import { IEducation } from '../util/commonTypes'
import { CloseIcon } from '../assets/Icons'
import { Label } from '@fluentui/react'

interface IAddEducation {
  optionsReducer: React.Dispatch<IAction>
  educationList: IEducation[]
}

interface IEducationForm {
  index: number
  optionsReducer: React.Dispatch<IAction>
  remove: (index: number) => void
  education: IEducation
}

const initialEducation: IEducation = {
  degreeType: '',
  fieldOfStudy: '',
  graduationDate: '',
  schoolName: ''
}

function EducationForm ({ index, optionsReducer, remove, education }: IEducationForm) {
  const handleDateChange = useCallback((path: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    optionsReducer({ path: `education[${index}].${path}`, value: ev.target.value })
  }, [optionsReducer, index])

  const update = useCallback((path: string) => (ev: any, value: string | undefined) => {
    optionsReducer({ path: `education[${index}].${path}`, value })
  }, [optionsReducer, index])

  return <Stack className='bg-gray-100 p-4'>
    <Stack horizontalAlign='end' style={{ marginBottom: -20 }}>
      <button onClick={() => remove(index)}><CloseIcon /></button>
    </Stack>
    <Stack horizontal spacing={16} className='mt-4'>
      <FWTextField required value={education.degreeType} onChange={update('degreeType')} label='Degree Type' />
      <FWTextField required value={education.fieldOfStudy} onChange={update('fieldOfStudy')} label='Field of study' />
    </Stack>
    <Stack horizontal spacing={16}>
      <FWTextField required value={education.schoolName} onChange={update('schoolName')} label='School Name' />
      <Stack styles={{ root: { width: '100%' } }}>
        <Label required htmlFor='graduationYear'>Graduation Year</Label>
        <input
        // className={errors.dob ? 'hasError' : ''}
        type="date"
        id="graduationYear"
        name="graduationYear"
        onChange={handleDateChange('graduationYEar')}
        value={education.graduationDate} />
        {/* {errors.dob && <ErrorMessage>{errors.dob}</ErrorMessage>} */}
      </Stack>
    </Stack>
  </Stack>
}

export function AddEducationForm ({ optionsReducer, educationList }: IAddEducation) {
  const addEducation = useCallback(() => {
    optionsReducer({ path: 'education', value: [...educationList, Object.assign({}, initialEducation)] })
  }, [optionsReducer, educationList])

  const deleteEducation = useCallback((index: number) => {
    const newArray = [...educationList]
    newArray.splice(index, 1)
    optionsReducer({ path: 'education', value: newArray })
  }, [optionsReducer, educationList])

  return <Stack className='mt-5' spacing={16}>
    {educationList.map((e, i) => <EducationForm key={i} index={i} optionsReducer={optionsReducer} remove={deleteEducation} education={e} />)}
    <PrimaryButton text='Add Education' onClick={addEducation} />
  </Stack>
}
