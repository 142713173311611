import { TooltipHost, ITooltipHostProps } from '@fluentui/react'
import React from 'react'

interface ITooltipButton extends ITooltipHostProps {
  name: string
  tooltip?: boolean
}

export const TooltipButton: React.FC<ITooltipButton> = ({ name, children, tooltip, ...props }) => {
  if (!tooltip) return <>{children}</>

  return <TooltipHost
    id={name}
    content={name}
    calloutProps={{
      isBeakVisible: false
    }}
    {...props}
  >
    {children}
  </TooltipHost>
}
