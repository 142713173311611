import { DateTime } from 'luxon'
import { IAddress } from './commonTypes'

const isNumber = (value: any): value is number => {
  return typeof value === 'number'
}

const isString = (value: any): value is string => {
  return typeof value === 'string'
}

const isArray = <T>(value: any): value is Array<T> => {
  return Array.isArray(value)
}

export const isBooleanObject = (value: any): value is { [key: string]: boolean, [key: number]: boolean } => {
  let isObject = true
  if (typeof value === 'object' && value !== null && !isArray(value)) {
    for (const key of Object.keys(value)) {
      if (typeof value[key] !== 'boolean') {
        isObject = false
      }
    }
  }
  return isObject
}

export const classNames = (...args: any[]) => {
  const classes: (string | number)[] = []
  for (const className of args) {
    if (!className) continue

    if (isNumber(className) || isString(className)) {
      classes.push(className)
    } else if (isArray(className) && className.length > 0) {
      const joinedClassNames = classNames(...className)
      if (joinedClassNames) {
        classes.push(joinedClassNames)
      }
    } else if (isBooleanObject(className)) {
      for (const key of Object.keys(className)) {
        if (className[key]) {
          classes.push(key)
        }
      }
    }
  }

  return classes.join(' ')
}

export function formatAddress ({ address, city, state, zipcode }: IAddress) {
  const fullAddress = [address, city, state, zipcode] as string[]

  return fullAddress.join()
}

export function formatDate (date: string) {
  return DateTime.fromISO(date).toFormat('LLL dd, yyyy')
}

// handling
export const onDrop = async (acceptedFiles: any, rejectedFiles: any) => {
  if (!acceptedFiles.length || acceptedFiles.length > 1 || rejectedFiles.length) return

  const file = acceptedFiles[0]

  // const res = await api.postWithFiles(process.env.REACT_APP_API_BASE_URL+'/assets/candidate/can_cXIShYwu9uREjbf', { uploads: [file] })
  // console.log(res)
  const reader = new FileReader()

  reader.onabort = () => console.log('file reading was aborted')
  reader.onerror = () => {
    console.log('error')
  }
  reader.onload = () => {
    console.log('LOADING')
    if (reader.result && typeof reader.result === 'string') {
      // const { data }: { data: string[][] } = parse(reader.result)
      const data = [] as any[]
      if (!data.length) console.log('no data')

      const headers = data.shift()
      if (!headers) return

      const disciplines = data.map(d => d[1]).filter((item, i, arr) => arr.indexOf(item) === i)

      const jobs = data.reduce((acc, curr) => {
        const discipline = curr[1]
        if (!acc[discipline]) acc[discipline] = []

        acc[discipline].push({
          job: curr[0],
          specialty: curr[2],
          extra: curr[3]
        })
        return acc
      }, {} as { [index: string]: any[] })

      console.log(disciplines)
      console.log(jobs)
      // uploadStuff(jobs, disciplines)

      // console.log(jobs)
    }
  }

  reader.readAsBinaryString(file)
  // handleChange('resume')(undefined, file)
}

export function onlyUnique (value: any, index: number, array: any[]) {
  return array.indexOf(value) === index
}

export function pluralize (array: any[], names?: [string, string]) {
  if (array?.length > 1) {
    if (names?.length) return names[0]
    else return 's'
  } else {
    if (names?.length) return names[1]
    else return ''
  }
}

export function capitalizeFirst (word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}
