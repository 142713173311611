import React, { useCallback, useContext, useReducer } from 'react'
import { DefaultButton, Panel, PanelType, Pivot, PivotItem, Spinner } from '@fluentui/react'
import styled from 'styled-components'
import { IAction, commonReducer } from '../Common'
import { PrimaryButton, Stack } from '../Common/Common'
import { CandidateSearch } from './CandidateSearch'
import { Header } from '../Common/Header'
import { AddCandidateForm } from './AddCandidateForm'
import { CREATE_CANDIDATE, UPDATE_CANDIDATE } from '../queries/Candidates'
import { APICandidate, APIDiscipline, APIErrors, ICandidate, OrganizationType } from '../util/commonTypes'
import { CandidatesTable } from './CandidatesTable'
import { CandidatesView } from './CandidatesView'
import { AddExperienceForm } from './AddExperienceForm'
import { AddEducationForm } from './AddEducation'
import { useLoaderData, useNavigate, useRevalidator, useSearchParams } from 'react-router-dom'
import { api } from '../util/api'
import { Toast } from '../Toast/Toast'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { OrgContext } from '../Nav/OrgContext'
import { DateTime } from 'luxon'
import { NonFatalError } from '../Common/Error'

const Container = styled.main`
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

  @media (max-width: 600px) {
    .form {
      grid-template-columns: 1fr 1fr;
    }
  }
`

const initialOptions = {
  isOpen: false,
  errors: [],
  candidates: [],
  selectedCandidate: null,
  experience: [],
  education: [],
  loading: false,
  edit: false
}

const initialSearchState = {
  disciplines: [],
  states: [],
  status: undefined
}

const initialCandidate: ICandidate = {
  firstName: '',
  lastName: '',
  dob: '',
  mobileNumber: '',
  discipline: undefined,
  email: '',
  address: {
    address: '',
    city: '',
    state: '',
    zipcode: ''
  },
  jobs: [],
  resume: undefined
}

const validateCandidate = ({ firstName, lastName, dob, email, mobileNumber, address, resume, discipline, jobs }: ICandidate, edit: boolean) => {
  const errors: any = {}
  if (!firstName) errors.firstName = 'First name is required'
  if (!lastName) errors.lastName = 'Last name is required'
  if (!dob) {
    errors.dob = 'Date of birth is required'
  } else {
    const date = DateTime.fromFormat(dob, 'yyyy-MM-dd').toMillis()
    const now = DateTime.now().toMillis()
    if (now < date) errors.dob = 'Invalid Date'
    else if (DateTime.isDateTime(date)) errors.dob = 'Invalid date'
  }
  if (!email) errors.email = 'Email is required'

  if (!mobileNumber) {
    errors.mobileNumber = 'Mobile number is required'
  } else if (!isPossiblePhoneNumber(mobileNumber)) errors.mobileNumber = 'Please enter a valid phone number'

  if (!address.address) errors.address = 'Address is required'
  if (!edit && !resume) errors.resume = 'Resume is required'
  if (!discipline) errors.discipline = 'Ddiscipline is required'
  if (!jobs) errors.job = 'Job title is required'
  return errors
}

// const validateExperience = (experience: IExperience[]) => {
//   return experience.map(e => {
//     const err: { [index: string]: string } = {}
//     Object.entries(e).forEach(([key, value]) => {
//       if (!value) err[key] = `${key} is required`
//     })
//     return err
//   })
// }

// const validateEducation = (education: IEducation[]) => {
//   return education.map(e => {
//     const err: { [index: string]: string } = {}
//     Object.entries(e).forEach(([key, value]) => {
//       if (!value) err[key] = `${key} is required`
//     })
//     return err
//   })
// }

export function Candidates () {
  const revalidator = useRevalidator()
  const data = useLoaderData() as { candidates: APICandidate[], disciplines: APIDiscipline[], errors?: [] }

  const orgType = useContext(OrgContext)

  const [options, optionsReducer]: [any, React.Dispatch<IAction>] = useReducer(commonReducer, initialOptions)
  const [searchState, searchReducer] = useReducer(commonReducer, initialSearchState)
  const [candidate, candidateReducer]: [ICandidate, React.Dispatch<IAction>] = useReducer(commonReducer, initialCandidate)

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  console.log(searchParams)
  const candidates = data.candidates

  const openPanel = useCallback(() => {
    optionsReducer({ value: true, path: 'isOpen' })
  }, [])

  const dismissPanel = useCallback((ev?: any) => {
    if (!ev?.target?.className.includes('pac-')) {
      const candidates = options.candidates
      candidateReducer({ type: 'reset', value: initialCandidate })
      optionsReducer({
        type: 'reset',
        value: {
          ...initialOptions,
          candidates
        }
      })
    }
  }, [options.candidates])

  const handleSearchChange = useCallback((path: string, options?: { multiselect?: boolean }) => (event: any, value: any) => {
    if (options?.multiselect) {
      if (value.selected) return searchReducer({ type: 'update', value: [...searchState[path], value.key], path })
      else return searchReducer({ type: 'update', path, value: [...searchState[path]].filter(k => k !== value.key) })
    }

    searchReducer({ type: 'update', value, path })
  }, [searchState])

  const handleCandidate = useCallback((path: keyof ICandidate, options?: { multiselect?: boolean }) => (event: any, value: any) => {
    optionsReducer({ type: 'remove', path: `errors.${path}` })
    if (options?.multiselect) {
      const newValue = candidate[path] as string[]
      if (value.selected) return candidateReducer({ type: 'update', value: [...newValue, value.key], path })
      else return candidateReducer({ type: 'update', path, value: [...newValue].filter(k => k !== value.key) })
    }

    candidateReducer({ type: 'update', value, path })
  }, [candidate])

  const submitResume = useCallback(async (id: string) => {
    if (!candidate.resume) return
    const res = await api.postWithFiles(process.env.REACT_APP_API_BASE_URL + '/assets/candidate/' + id, { uploads: [candidate.resume] })
    if (!res.ok) {
      Toast.makeText({ message: 'Could not upload resume, please try again in candidate file', duration: 3000 })
    }
  }, [candidate.resume])

  const handleSubmit = useCallback(async () => {
    const errors = validateCandidate(candidate, options.edit)

    if (Object.values(errors).length) {
      return optionsReducer({ type: 'update', value: errors, path: 'errors' })
    }
    optionsReducer({ path: 'loading', value: true })
    const newCandidate = {
      ...candidate,
      id: undefined,
      specialties: undefined,
      jobInfo: undefined,
      discipline: candidate.discipline?.id,
      jobs: candidate.jobs?.map(j => j.id),
      specialty: candidate.specialty?.map(j => j.id)
    }

    delete newCandidate.id
    delete newCandidate.resume
    delete newCandidate.specialties
    delete newCandidate.jobInfo

    let query, args

    if (options.edit) {
      query = UPDATE_CANDIDATE
      args = { args: newCandidate, id: candidate.id }
    } else {
      query = CREATE_CANDIDATE
      args = { args: newCandidate }
    }

    try {
      const res = await api.query(query, args)
      const id = res?.createCandidate?.candidate?.id
      const updateCandidateSuccess = res?.updateCandidate?.success
      if ((res?.createCandidate?.success && id) || updateCandidateSuccess) {
        await submitResume(id || candidate.id)
        revalidator.revalidate()
        dismissPanel()
      } else if (res?.createCandidate?.messages?.length) {
        const errors = res.createCandidate.messages as APIErrors[]
        errors.map(({ message }) => Toast.makeText({ message, duration: 3000, type: 'alert' }))
        optionsReducer({ type: 'update', value: errors, path: 'errors' })
      } else if (res.errors) {
        const errors = res.errors as string[]
        errors.map(e => Toast.makeText({ message: e, duration: 3000 }))
      }
    } catch (e) {
      Toast.makeText({ message: 'Unknown error occurred. Please try again.', duration: 3000 })
      console.log(e)
    }
    optionsReducer({ path: 'loading', value: false })
  }, [candidate, dismissPanel, revalidator, submitResume, options.edit])

  const handleSearch = useCallback(() => {
    const params = {} as any
    if (searchState.status?.key) {
      params.status = searchState.status.key
    }

    if (searchState.states?.length) {
      params.states = searchState.states.join(',')
    }

    if (searchState.disciplines?.length) {
      params.disciplines = searchState.disciplines.join(',')
    }

    setSearchParams(params)
  }, [searchState, setSearchParams])

  const resetSearchState = useCallback(() => {
    searchReducer({ type: 'reset', value: initialSearchState })
    setSearchParams({})
  }, [setSearchParams])

  const handleCandidateEdit = useCallback((candidate: APICandidate) => {
    const discipline = data.disciplines.find(d => d.discipline === candidate.discipline)
    optionsReducer({ type: 'set', value: { isOpen: true, edit: true } })
    candidateReducer({
      type: 'reset',
      value: {
        ...candidate,
        dob: DateTime.fromFormat(candidate.dob, 'MM-dd-yyyy').toFormat('yyyy-MM-dd'),
        discipline: {
          id: discipline?.id,
          value: discipline?.discipline,
          label: discipline?.discipline
        },
        jobs: candidate.jobInfo?.map(j => ({
          id: j.job_id,
          value: j.job,
          label: j.job
        })),
        specialty: candidate.jobInfo?.filter(j => j.hasSpecialty).map(s => ({
          id: s.job_id,
          value: s.specialty,
          label: s.specialty
        }))
      }
    })
  }, [data.disciplines])

  const onRenderFooterContent = useCallback(() => {
    return <Stack horizontal spacing={16} horizontalAlign='end'>
      <DefaultButton text='Cancel' onClick={dismissPanel} style={{ backgroundColor: 'white' }} />
      <PrimaryButton onClick={handleSubmit} disabled={options.loading}>
        {options.loading ? <Spinner /> : 'Submit'}
      </PrimaryButton>
    </Stack>
  }, [dismissPanel, handleSubmit, options.loading])

  if (data?.errors?.length) return <NonFatalError errors={data.errors} />

  return <>
    <Container>
      <Header>
        <h1 className='text-4xl text-center m-0'>Candidates</h1>
        {orgType !== OrganizationType.CLIENT && <PrimaryButton text='Add Candidate' onClick={openPanel} />}
      </Header>
      <Stack className='bg-gray-100 p-8 pt-0'>
        <CandidateSearch handleChange={handleSearchChange} state={searchState} disciplines={data.disciplines} />
        <Stack horizontal horizontalAlign='center' spacing={16} className='pt-6 sm:pt-0'>
          <PrimaryButton text='Search' onClick={handleSearch} />
          <DefaultButton text='Reset' onClick={resetSearchState} />
        </Stack>
      </Stack>
      <CandidatesTable
        data={candidates}
        handleClick={(candidate) => navigate('/candidates/' + candidate.id)}
        handleEdit={handleCandidateEdit}
      />
    </Container>
    <Panel
      isFooterAtBottom
      isOpen={options.isOpen}
      type={PanelType.customNear}
      customWidth='500px'
      onDismiss={dismissPanel}
      headerText={options.edit ? `Editing ${candidate.firstName} ${candidate.lastName}` : 'Adding New Candidate'}
      onRenderFooterContent={onRenderFooterContent}
    >
      <Pivot>
        <PivotItem headerText='Candidate'>
          <AddCandidateForm state={candidate} handleChange={handleCandidate} errors={options.errors} disciplines={data.disciplines} />
        </PivotItem>
        <PivotItem headerText='Experience'>
          <AddExperienceForm optionsReducer={optionsReducer} experienceList={options.experience} />
        </PivotItem>
        <PivotItem headerText='Education'>
          <AddEducationForm optionsReducer={optionsReducer} educationList={options.education} />
        </PivotItem>
        <PivotItem headerText='Credentials'>
        </PivotItem>
      </Pivot>
    </Panel>
    <Panel
      isLightDismiss
      isFooterAtBottom
      isOpen={options.selectedCandidate}
      type={PanelType.customNear}
      customWidth='500px'
      onDismiss={() => { optionsReducer({ path: 'selectedCandidate', value: null }) }}
      onRenderHeader={() => <h1 className='w-full ml-9'>{options.selectedCandidate?.firstName} {options.selectedCandidate?.lastName}</h1>}
    >
      {options.selectedCandidate && <CandidatesView data={options.selectedCandidate} />}
    </Panel>
  </>
}
