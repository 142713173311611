import { redirect } from 'react-router-dom'
import { GET_USER_PERMISSIONS } from '../queries/Users'

// TODO -  better way to do this?
declare const process : {
  env: {
    REACT_APP_API_BASE_URL: string
  }
}

class API {
  public token?: string
  // eslint-disable-next-line
  public fetch!: (info: RequestInfo, init?: RequestInit) => Promise<Response>
  // protected savedConfig: Record<string, string>folder => folder.id)
  protected ready!: () => void
  protected apiBase: string

  constructor () {
    this.apiBase = process.env.REACT_APP_API_BASE_URL
  }

  async getPermissions () {
    try {
      const response = await this.fetch(this.apiBase, {
        method: 'POST',
        headers: {
          // Authorization: `Bearer ${this.token ?? ''}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          query: GET_USER_PERMISSIONS
        })
      })
      if (response.ok) {
        const { data } = await response.json()
        if (data.user.length) {
          const user = data.user[0]
          const { type } = user.organization

          sessionStorage.setItem('mlee-vms-permissions', type)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  async query <ReturnType = any> (query: string, variables?: any, querySignature?: string): Promise<ReturnType> {
    const permissions = sessionStorage.getItem('mlee-vms-permissions')
    if (!permissions) await this.getPermissions()
    try {
      const response = await this.fetch(this.apiBase, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          query,
          variables,
          extensions: {
            querySignature
          }
        })
      })
      if (!response.ok) {
        if (response.status === 401) {
          return redirect('/login') as any
        } else {
          const json = await response.json()
          return json
        }
      }
      const gqlresponse = await response.json()
      if (gqlresponse.errors?.length) {
        const errors = gqlresponse.errors.map((e:any) => e.message)
        // toast(gqlresponse.errors[0].message)
        // gqlresponse.errors.forEach((e: any) => {
        //   Toast.makeText({ message: e?.message || 'Error', type: 'alert', duration: 3000 })
        // })
        console.log(gqlresponse)
        return { errors, status: response.status, statusText: response.statusText } as any
      }
      return gqlresponse.data
    } catch (e: any) {
      console.log(e)
      // Toast.makeText({ message: e, type: 'alert', duration: 3000 })
      throw e
      // return {} as any
    }
  }

  async restful <ReturnType = any> (url: string, body?: any, method = 'GET') {
    try {
      const resp = await this.fetch(this.apiBase + url, {
        headers: {
          Authorization: `Bearer ${this.token ?? ''}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method,
        body
      })
      if (!resp.ok) {
        if (resp.status === 401) {
          // uiConfig.login.handleUnauthorized(environmentConfig)
          throw new Error(resp.statusText)
        } else {
          // toast(resp.statusText)
          console.log(resp.statusText)
          throw new Error(resp.statusText)
        }
      }
      return await resp.json() as ReturnType
    } catch (e: any) {
      // toast(e.message)
      console.log(e.message)
      throw e
    }
  }

  // the data object passed to this function should contain an
  // `uploads` field that consists of an array of javascript File objects
  // see https://developer.mozilla.org/en-US/docs/Web/API/File
  async postWithFiles (endpoint:string, data:any) {
    const formData = new FormData()
    const postData = {} as any
    // postData.uploads = []
    for (const file of data.uploads) {
      formData.append('file', file)
      postData.filename = file.name
      postData.type = file.docType
    }
    formData.append('data', JSON.stringify(postData))

    const res = await this.fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${this.token ?? ''}`
        // Accept: 'application/json',
        // 'Content-Type': 'mulipart/form-data'
      },
      method: 'post',
      body: formData
    })

    console.log(res)

    return res || {}
  }

  // async getSelf () {
  //   const { users, access } = await this.query<GlobalSelf>(GET_GLOBAL_SELF)
  //   return { me: users[0], access }
  // }
}

export const api = new API()
api.fetch = fetch.bind(this)
