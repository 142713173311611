import { Link, useLoaderData, useParams } from 'react-router-dom'
import { NotFound } from '../NotFound/NotFound'
import { FWTextField, PrimaryButton, Stack } from '../Common/Common'
import { FormEvent, useCallback, useReducer } from 'react'
import { UserNoOutlineIcon } from '../assets/Icons'
import { commonReducer } from '../Common'
import { theme } from '../util/theme'
import { Spinner } from '@fluentui/react'
import styled from 'styled-components'

const initialOptions = {
  rePassword: '',
  password: '',
  error: '',
  loading: false,
  success: false
}

const UL = styled.ul`
  padding-left: 16px;
  li {
    font-size: 12px;
    list-style-type: disc;
  }
`

function PasswordRequirements () {
  return <Stack className='slide-in mt-4'>
    <p className='m-0 text-sm text-red-900'>Password does not meet requirements</p>
    <UL>
      <li>Must be at least 8 characters long</li>
      <li>Must have at least 1 uppercase letter</li>
      <li>Must have at least 1 lowercase letter</li>
      <li>Must have at least 1 special character (! @ # $ % ^ & *)</li>
    </UL>
  </Stack>
}

function Success () {
  return <main className='grid px-8 sm:px-16 h-screen p-0 items-center slide-in'>
    <Stack horizontalAlign='center' spacing={24}>
      <h1 className='text-4xl text-center tracking-tight text-blue-900 sm:text-5xl relative z-10 font-medium' style={{ width: 300 }}>
        <svg style={{ top: 50 }} aria-hidden="true" viewBox="0 0 418 42" className="absolute left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
        <span className="relative">Success</span>
      </h1>
      {/* <p className='text-2xl text-center font-light'>
        A reset link has been sent to your account!
      </p> */}
      <Link to='/login' className='text-blue-900 mt-4 underline'>Back to login</Link>
    </Stack>
  </main >
}

export function PasswordResetToken () {
  const params = useParams()
  const data: any = useLoaderData()
  const [{ password, rePassword, error, loading, success }, reducer] = useReducer(commonReducer, initialOptions)

  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password !== rePassword) return reducer({ path: 'error', value: 'Passwords do not match' })

    const res = await fetch(process.env.REACT_APP_API_BASE_URL + `/password-reset/${params.token}`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ password, rePassword })
    })

    if (res.status !== 200) {
      console.log('error')
      try {
        const json = await res.json()
        reducer({ path: 'error', value: json?.error ?? 'Unknown error occurred. Please try again or contact us if the problem persists.' })
      } catch (e) {
        reducer({ path: 'error', value: 'Unknown error occurred. Please try again or contact us if the problem persists.' })
      }
    } else reducer({ path: 'success', value: true })
  }, [password, rePassword, params.token])

  const handleChange = useCallback((path: string) => (ev: any, value: string | undefined) => {
    reducer({
      type: 'set',
      value: {
        [path]: value,
        error: ''
      }
    })
  }, [])

  if (!data?.json?.success) {
    return <NotFound
      code={data?.status || '500'}
      message={data?.statusText || 'Refresh your page and try again'}
      description={data?.json?.error || 'Unexpected Error'}
      href='/password-reset'
      linkText='Resend Link'
    />
  }

  return success
    ? <Success />
    : <main className='p-0 h-screen'>
      <Stack
        verticalAlign='center'
        horizontalAlign='center'
        verticalFill
        className='sm:p-16 p-8'
      >
        <form onSubmit={handleSubmit} className='relative sm:w-1/2 w-full flex flex-col justify-center'>
          <Stack horizontalAlign='center'>
            <div style={{ borderRadius: 500, border: ` 3px solid ${theme.blue.hex()}`, padding: 20 }}>
              <UserNoOutlineIcon size={64} color={theme.blue.hex()} />
            </div>
          </Stack>
          <h1 className='text-blue-900 text-center font-semibold'>Reset password</h1>
          <FWTextField required label="New Password" type='password' onChange={handleChange('password')} />
          <FWTextField required label="Confirm Password" type='password' onChange={handleChange('rePassword')} />
          {(error && error.includes('not meet requirements'))
            ? <PasswordRequirements />
            : error
              ? <p className='m-0 text-sm text-red-900 slide-in mt-4'>{error}</p>
              : null}
          <PrimaryButton
            type='submit'
            className='mt-4'
            onRenderText={() => loading ? <Spinner key='spinner' className='fade-in' /> : <span key='reset password'>Reset Password</span>} />
        </form>
      </Stack>
    </main>
}
