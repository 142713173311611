export const GET_CLIENTS = `
  query GetClients($id: String) {
    clients(id: $id) {
      id
      name
      description
      email
      phoneNumber
      address {
        address
        city
        state
        zipcode
      }
    }
  }
`

export const GET_CLIENT = `
  query GetClients($id: String) {
    clients(id: $id) {
      id
      name
      description
      email
      phoneNumber
      address {
        address
        city
        state
        zipcode
      }
      requisitions {
        id
        startDate
        endDate
        billRate
        status
        guaranteedHours
        onCall
        shiftType
        discipline_id
        job_id
        organization_id
        comments
        job
        discipline
        city
        state
        specialty
      }
    }
  }
`

export const CREATE_CLIENT = `
  mutation CreateClient($args: ClientInput!) {
    createClient(args: $args) {
      messages {
        message
        type
        arg
      }
      success
    }
  }
`
