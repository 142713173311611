import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'

import './index.css'
import './table.css'
import { Home } from './Home'
import reportWebVitals from './reportWebVitals'
import { Nav } from './Nav/Nav'
import { Requisitions } from './Requisition/Requisitions'
import { Candidates } from './Candidates/Candidates'
import './util/theme'
import { Error } from './Common/Error'
import { NotFound } from './NotFound/NotFound'
import { Login } from './Login/Login'
import { BecomePartner } from './Partners/BecomePartner'
import { Partners } from './Partners/Partners'
import { GET_PARTNER, GET_PARTNERS } from './queries/Partners'
import { Partner } from './Partners/Partner'
import { PasswordReset } from './Login/PasswordReset'
import { PasswordResetToken } from './Login/PasswordResetToken'
import { RequisitionStatus, Status } from './util/commonTypes'
import { Clients } from './Clients/Clients'
import { GET_CLIENT, GET_CLIENTS } from './queries/Clients'
import { api } from './util/api'
import { GET_ORG_USERS, GET_USERS } from './queries/Users'
import { Client } from './Clients/Client'
import { GET_CANDIDATE, GET_CANDIDATES } from './queries/Candidates'
import { Profile } from './Profile/Profile'
import { DefaultButton, TooltipHost } from '@fluentui/react'
import { Candidate } from './Candidates/Candidate'
import { GET_REQUISITIONS } from './queries/Requisitions'
import { Requisition } from './Requisition/Requisition'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <Nav authenticated>
        <Home />
      </Nav>,
    loader: () => {
      return api.query(GET_USERS)
    },
    errorElement: <Error />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/become-partner',
    element: <Nav>
      <BecomePartner />
    </Nav>
  },
  {
    path: '/requisition',
    element: <Nav authenticated>
      <Requisitions />
    </Nav>,
    loader: ({ request }) => {
      const url = new URL(request.url)
      const searchParams = url.searchParams
      const status = {
        open: RequisitionStatus.OPEN,
        inprogress: RequisitionStatus.INPROGRESS,
        closed: RequisitionStatus.CLOSED
      } as any

      return api.query(GET_REQUISITIONS, {
        filters: {
          status: status[searchParams.get('status') ?? ''],
          states: searchParams.get('states')?.split(','),
          disciplines: searchParams.get('disciplines')?.split(',')
        }
      })
    },
    errorElement: <Error />
  },
  {
    path: '/requisition/:id',
    element: <Nav authenticated>
      <Requisition />
    </Nav>,
    errorElement: <Error />,
    loader: ({ params }) => {
      return api.query(GET_REQUISITIONS, {
        filters: {
          id: params.id
        }
      })
    }
  },
  {
    path: '/candidates',
    element: <Nav authenticated>
      <Candidates />
    </Nav>,
    loader: ({ request }) => {
      const url = new URL(request.url)
      const searchParams = url.searchParams

      return api.query(GET_CANDIDATES, {
        filters: {
          states: searchParams.get('states')?.split(','),
          disciplines: searchParams.get('disciplines')?.split(',')
        }
      })
    },
    errorElement: <Error />
  },
  {
    path: '/candidates/:id',
    element: <Nav authenticated>
      <Candidate />
    </Nav>,
    loader: ({ params }) => {
      return api.query(GET_CANDIDATE, { id: params.id })
    },
    errorElement: <Error />
  },
  {
    path: '/partners',
    element: <Nav authenticated>
      <Partners />
    </Nav>,
    loader: () => {
      return api.query(GET_PARTNERS, {
        status: [Status.NEW, Status.VIEWED, Status.APPROVED]
      })
    },
    errorElement: <Error />
  },
  {
    path: '/partners/:id',
    element: <Nav authenticated>
      <Partner />
    </Nav>,
    loader: ({ params }) => {
      return api.query(GET_PARTNER, { id: params.id })
    },
    errorElement: <Error />
  },
  {
    path: '/password-reset',
    element: <PasswordReset />
  },
  {
    path: '/password-reset/:token',
    element: <Nav>
      <PasswordResetToken />
    </Nav>,
    loader: async ({ params }) => {
      const res = await fetch(process.env.REACT_APP_API_BASE_URL + `/password-reset/${params.token}`)
      const json = await res.json()

      return {
        json,
        status: res.status,
        statusText: res.statusText
      }
    },
    errorElement: <Error />
  },
  {
    path: '/clients',
    element: <Nav authenticated>
      <Clients />
    </Nav>,
    loader: async () => {
      return api.query(GET_CLIENTS)
    },
    errorElement: <Error />
  },
  {
    path: '/clients/:id',
    element: <Nav authenticated>
      <Client />
    </Nav>,
    loader: ({ params }) => {
      return api.query(GET_CLIENT, { id: params.id })
    },
    errorElement: <Error />
  },
  {
    path: '/profile',
    element: <Nav authenticated>
      <Profile />
    </Nav>,
    loader: async () => {
      return api.query(GET_ORG_USERS, { filters: { owned: true } })
    },
    errorElement: <Error />
  },
  {
    path: '/test',
    element: <TooltipHost
      id='tooltipId'
      content="This is the tooltip content"
      calloutProps={{ gapSpace: 0 }}
      styles={{ root: { display: 'inline-block' } }}
    >
      <DefaultButton aria-describedby={'tooltipId'}>Hover over me</DefaultButton>
    </TooltipHost>
  },
  {
    path: '/files',
    element: <Nav authenticated>
      <main>
        <h1>Files</h1>
      </main>
    </Nav>
  },
  {
    path: '*',
    element: <Nav notFound>
      <NotFound />
    </Nav>
  }
])

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
