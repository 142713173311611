import { Link, useLoaderData } from 'react-router-dom'
import { APIAnalytics, APIUser } from './util/commonTypes'
import { NotFound } from './NotFound/NotFound'
import styled from 'styled-components'
import { Stack } from './Common/Common'

const Container = styled.section`
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 30px;
  margin-top: 30px;
`
const Card = styled(Stack)`
  background-color: #F3F4F6;
  width: 300px;
  padding: 30px;
  a {
    font-size: 24px;
    font-weight: 300;
    text-decoration: underline;
  }
`

export function Home () {
  const data = useLoaderData() as { users: APIUser[], analytics: APIAnalytics }

  if (!data?.users?.length) return <NotFound code='500' message='Something went wrong' description='Refresh your page and try again' />

  const user = data.users[0]

  return (
    <main className="App">
      <h1 className="text-6xl text-center pt-10 font-thin">
        Welcome, {user.fullName}
      </h1>
      <Container className='grid sm:grid-cols-2 grid-cols-1'>
        <Card className='card'>
          <Link to='/requisition'>Requisitions</Link>
          <span>Open: {data.analytics.openRequisitions}</span>
          <span>Closed: {data.analytics.filledRequisitions}</span>
        </Card>
        <Card className='card'>
          <Link to='/candidates'>Candidates</Link>
          <span>Candidates: {data.analytics.candidates}</span>
          <span>Submitted: {data.analytics.submittedCandidates}</span>
        </Card>
      </Container>
    </main>
  )
}
