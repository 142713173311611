import styled from 'styled-components'
import { Link, useRouteError } from 'react-router-dom'
import { NotFound } from '../NotFound/NotFound'
import { Stack } from './Common'
import { FilledAlertIcon } from '../assets/Icons'

interface IError {
  code?: string
  errors: string[]
  description?: string
  href?: string
  linkText?: string
}

export function Error () {
  const error: any = useRouteError()
  console.log(error)
  return <NotFound code='500' message='Something went wrong' description='Refresh your page and try again' />
}

export function NonFatalError ({ errors, href, linkText }:IError) {
  return <main className='p-0'>
  <Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { height: '100vh' } }}>
    <h1 className='text-base font-semibold text-blue-900 text-5xl sm:text-5xl'>Error</h1>
    <FilledAlertIcon size={100} />
    {errors.map((e, i) => <span key={i} className='mt-4 text-3xl tracking-tight text-gray-900 sm:text-4xl text-center'>
      {e}
    </span>)}
    <div className='mt-10 flex items-center justify-center gap-x-6'>
      <Link to={href || '/'} className='rounded-sm bg-blue-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-200 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900'>{linkText || 'Go back home'}</Link>
    </div>
  </Stack>
</main>
}

export function ErrorCard ({ error, text }:{ text: string, error?: boolean }) {
  return <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded slide-in" role="alert" style={{ top: 200 }}>
  {error && <strong className="font-bold">Error!&nbsp;</strong>}
  <span className="block sm:inline">{text}</span>
</div>
}

export const ErrorMessage = styled.p.attrs({ className: 'error-in' })`
  padding-top: 5px;
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
`
