import React, { useCallback, useMemo, useState } from 'react'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { APIErrors, APIPartner, Status } from '../util/commonTypes'
import { ThreeDotsVerticalIcon } from '../assets/Icons'
import { FWTextField, PrimaryButton, Stack } from '../Common/Common'
import { NotFound } from '../NotFound/NotFound'
import { DefaultButton, DirectionalHint, Dropdown, IContextualMenuProps, IDropdownOption } from '@fluentui/react'
import { ADD_PARTNER_COMMENT, UPDATE_PARTNER } from '../queries/Partners'
import { Header } from '../Common/Header'
import { Toast } from '../Toast/Toast'
import { NonFatalError } from '../Common/Error'
import { formatPhoneNumber } from 'react-phone-number-input'
import { api } from '../util/api'

export function Partner () {
  const data = useLoaderData() as { partners: APIPartner[], errors?: string[] }
  const revalidator = useRevalidator()

  const partners: APIPartner[] = data?.partners
  const partner = partners?.length ? partners[0] : { id: undefined }
  const [comment, setComment] = useState<string | undefined>()

  const menuProps: IContextualMenuProps = useMemo(() => {
    const props = {
      directionalHintForRTL: DirectionalHint.topCenter,
      directionalHintFixed: false,
      items: [
        { key: 'revoke', text: 'Revoke Access', onClick: () => { console.log('Handle CLick') } }
      ]
    }
    return props
  }, [])

  const statusOptions: IDropdownOption[] = [
    { key: Status.NEW, text: Status.NEW, disabled: true, hidden: true },
    { key: Status.VIEWED, text: Status.VIEWED, disabled: true, hidden: true },
    { key: Status.APPROVED, text: Status.APPROVED },
    { key: Status.DENIED, text: Status.DENIED }
  ]

  const handleStatusChange = useCallback(async (ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) => {
    if (!option) return
    // setPartner({ type: 'update', path: 'status', value: option.key })
    const res = await api.query(UPDATE_PARTNER, {
      id: `${partner.id}`,
      args: {
        status: option.key
      }
    })

    const errors = res?.errors as string[]
    const m = res?.updatePartner?.messages as APIErrors[]
    const messages = m?.map((m) => m.message)

    if (res?.updatePartner?.success) {
      revalidator.revalidate()
    } else if (errors?.length) {
      console.log(res)
      errors.forEach(e => Toast.makeText({ message: e, duration: 3000 }))
    } else if (messages?.length) messages.forEach(e => Toast.makeText({ message: e, duration: 3000 }))
  }, [partner.id, revalidator])

  const handleCommentChange = useCallback((ev: any, value: string | undefined) => {
    setComment(value)
  }, [])

  const addComment = useCallback(async () => {
    if (!comment) return
    try {
      const res = await api.query(ADD_PARTNER_COMMENT, {
        id: `${partner.id}`,
        comment
      })

      if (res?.addPartnerComment?.success) {
        setComment('')
        revalidator.revalidate()
      } else {
        console.log(res)
        Toast.makeText({ type: 'alert', message: 'Could not add a message at this time.' })
      }
    } catch (e) {
      Toast.makeText({ type: 'alert', message: 'Could not add a message at this time.' })
      console.log(e)
    }
  }, [partner.id, comment, revalidator])

  if (data?.errors) return <NonFatalError errors={data.errors} />
  if (!partner?.id) return <NotFound message='Not found' />

  return <main>
    <header className='p-4 bg-gray-100 mt-4 sm:mt-0'>
      <Stack horizontal horizontalAlign='space-between'>
        <Stack horizontal verticalAlign='center' spacing={8}>
          <h1 className='font-medium text-blue-900'>{[`${Status.NEW}`, `${Status.VIEWED}`].includes(partner.status) ? 'Potential partner' : 'Partner'}: <br />{partner.fullName}</h1><br />
        </Stack>
        <Stack horizontal verticalAlign='center'>
          {partner.status === Status.NEW || partner.status === Status.VIEWED
            ? <Dropdown
              selectedKey={partner.status}
              options={statusOptions}
              onChange={handleStatusChange}
              styles={{
                title: { backgroundColor: 'transparent', border: 'none', fontSize: 20, fontWeight: 600 },
                root: { minWidth: 100 }
              }}
            />
            : partner.status === Status.APPROVED
              ? <DefaultButton
                menuProps={menuProps}
                className='clear-btn'
                styles={{ menuIcon: { display: 'none' }, flexContainer: { border: 'none' } }}
                style={{ border: 'none' }}>
                <ThreeDotsVerticalIcon />
              </DefaultButton>
              : <span>Denied</span>}
        </Stack>
      </Stack>
    </header>
    <section className='p-8 bg-gray-100 mt-8'>
      <div className='flex flex-col sm:flex-row'>
        <Stack styles={{ root: { width: '100%' } }}>
          <span><b>Job Title:</b> {partner.jobTitle}</span>
          <span><b>Organization:</b> {partner.organization}</span>
          <span><b>Phone Number:</b> {formatPhoneNumber(partner.phoneNumber)}</span>
          <span><b>Email:</b> {partner.email}</span>
          <span><b>States worked with: </b>{partner.states}</span>
          <span><b>Docusign status: </b>{partner.docusignStatus}</span>
        </Stack>
        <Stack styles={{ root: { width: '100%' } }} spacing={16} horizontalAlign='end'>
          <FWTextField value={comment} label='Comments' multiline onChange={handleCommentChange} />
          <PrimaryButton text='Submit' styles={{ root: { width: 75 } }} onClick={addComment} />
        </Stack>
      </div>
      {(partner.partnerComment || partner.comments?.length)
        ? <div>
        <h2>Comments:</h2>
        {partner.partnerComment && <Stack horizontal>
          <span><b>{partner.fullName}</b></span>:  {partner.partnerComment}
        </Stack>}
        {partner.comments?.map((c, i) => <Stack key={i} horizontal>
          <span><b>{c.user}</b></span>:  {c.comment}
        </Stack>)}
      </div>
        : null}
    </section>
    {partner.status === Status.APPROVED && <Stack className='mt-8'>
      <Header>
        <h2 className='font-normal text-blue-900'>WHAT GOES HERE?</h2>
      </Header>
      {/* <RequisitionsTable data={requisitionData} handleClick={(value: any) => {}}/> */}
    </Stack>}
  </main>
}
