import { useCallback } from 'react'
import Sel, { Props } from 'react-select'
import { theme } from '../util/theme'

interface ISelect extends Props {
  error?: string
}

export function Select ({ error, ...props }: ISelect) {
  const setBorder = useCallback((focused: boolean) => {
    if (error) return `1px solid ${theme.danger.hex()} !important`
    return `${focused ? '2px' : '1px'} solid ${focused ? theme.blue.hex() : theme.blueInputBorder.hex()} ${focused ? '!important' : ''}`
  }, [error])

  return <Sel
    className='styled-multiselect'
    isClearable
    {...props}
    styles={{
      control: (base, props) => {
        const newStyles = Object.assign({}, base)
        newStyles.boxShadow = 'none'
        newStyles.border = setBorder(props.isFocused)
        newStyles.borderRadius = '2px'

        return newStyles
      },
      menu: (base, props) => {
        const newStyles = Object.assign({}, base)

        newStyles.zIndex = 9999999999999
        return newStyles
      }
    }}
  />
}
