export const CREATE_CANDIDATE = `
  mutation CreateCandidate($args: CandidateInput!) {
    createCandidate(args: $args) {
      success
      messages {
        arg
        message
        type
      }
      candidate {
        id
      }
    }
  }
`

export const UPDATE_CANDIDATE = `
  mutation UpdateCandidate($args: CandidateInput!, $id: String!) {
    updateCandidate(args: $args, id: $id) {
      success
      messages {
        arg
        message
        type
      }
    }
  }
`

export const GET_CANDIDATES = `
  query GetCandidates($filters: CandidateFilters) {
    disciplines {
      id
      discipline
    }
    candidates(filters: $filters) {
      id
      firstName
      lastName
      mobileNumber
      medicalLicense
      npi
      email
      dob
      jobs
      discipline
      specialties
      jobInfo {
        hasSpecialty
        job_id
        job
        specialty
      }
      address {
        address
        city
        state
        zipcode
      }
    }
  }
`
export const GET_CANDIDATES_FOR_SUBMITTAL = `
  query GetCandidates($filters: CandidateFilters) {
    candidates(filters: $filters) {
      id
      firstName
      lastName
      mobileNumber
      medicalLicense
      npi
      email
      dob
      jobs
      discipline
      specialties
      address {
        address
        city
        state
        zipcode
      }
    }
  }
`
export const GET_CANDIDATE = `
  query GetCandidates($id: String) {
    candidates(id: $id) {
      firstName
      lastName
      mobileNumber
      medicalLicense
      npi
      email
      dob
      jobs
      discipline
      resume
      specialties
      resumeMimeType
      address {
        address
        city
        state
        zipcode
      }
    }
  }
`
