import { Label } from '@fluentui/react'
import React from 'react'
import styled from 'styled-components'
import { Stack } from '../Common/Common'
import { APICandidate } from '../util/commonTypes'

const Container = styled(Stack)``

export const CandidatesView: React.FC<{ data: APICandidate }> = ({ data }) => {
  return <Container spacing={16}>
    <Stack horizontal spacing={8} verticalAlign='center' horizontalAlign='space-between' className='p-4'>
    <Stack>
        <Label>Discipline:</Label>
        {data.discipline}
      </Stack>
      <Stack>
        <Label>Jobs:</Label>
        {data.jobs.join(' ')}
      </Stack>
      <Stack>
        <Label>Number:</Label>
        <span>{data.mobileNumber}</span>
      </Stack>
    </Stack>
    <Stack horizontal horizontalAlign='space-between' className='bg-gray-100 p-4'>
      <Stack>
        <Label>Medical License:</Label>
        {data.medicalLicense || 'N/A'}
      </Stack>
      <Stack>
        <Label>NPI:</Label>
        {data.npi || 'N/A'}
      </Stack>
    </Stack>
  </Container>
}
