import React, { useMemo } from 'react'
import { Table, Header, HeaderCell, Body, Row, RowCell } from '../Common/Table'
import { APIUser } from '../util/commonTypes'
import { TableCalloutButtons } from '../Common/CalloutButtons'
import { EditIcon, TrashIcon } from '../assets/Icons'
import { theme } from '../util/theme'
import { Stack } from '../Common/Common'

interface IUsersTable {
  currentUser: APIUser
  data: APIUser[]
  handleEdit: (user: APIUser) => void
  handleDelete: (user: APIUser) => void
}

export const UsersTable: React.FC<IUsersTable> = ({ data, handleEdit, handleDelete, currentUser }) => {
  const body = useMemo(() => data.map((row, key) => {
    return <Row key={key}>
      <RowCell>{row.fullName}</RowCell>
      <RowCell>{row.userName}</RowCell>
      <TableCalloutButtons
        email={row.email}
        phoneNumber={row.phoneNumber}
        rowCellProps={{ className: 'flex justify-evenly' }}
      />
      {(currentUser.admin || currentUser.orgAdmin) && <RowCell>
        <Stack horizontal horizontalAlign='space-around'>
          <button onClick={() => handleEdit(row)}>
            <EditIcon color={theme.blue.hex()} size={25} />
          </button>
          <button onClick={() => handleDelete(row)}>
            <TrashIcon color={theme.blue.hex()} size={25} />
          </button>
        </Stack>
      </RowCell>}
    </Row>
  }), [data, handleEdit, handleDelete, currentUser.admin, currentUser.orgAdmin])

  return <Table id='requisitions-table' classNames={{ table: 'table' }}>
    <Header>
      <HeaderCell>Full Name</HeaderCell>
      <HeaderCell>User Name</HeaderCell>
      <HeaderCell></HeaderCell>
      {(currentUser.admin || currentUser.orgAdmin) && <HeaderCell style={{ width: 50 }}>Edit/Delete</HeaderCell>}
    </Header>
    <Body>
      {body}
    </Body>
  </Table>
}
