import React, { useCallback, useReducer } from 'react'
import { useLoaderData, useNavigate, useRevalidator } from 'react-router-dom'
import { Body, Header as TableHeader, HeaderCell, Row, RowCell, Table } from '../Common/Table'
import { APIClient, IClient } from '../util/commonTypes'
import { Header } from '../Common/Header'
import { PrimaryButton, Stack } from '../Common/Common'
import { DefaultButton, Panel, PanelType } from '@fluentui/react'
import { AddClientForm } from './AddClientForm'
import { IAction, commonReducer } from '../Common'
import { CREATE_CLIENT } from '../queries/Clients'
import { NonFatalError } from '../Common/Error'
import { EmptyState } from '../NotFound/NotFound'
import { formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import { api } from '../util/api'
import { Toast } from '../Toast/Toast'

interface IPartnersTable {
  data: APIClient[]
  handleClick: (value: any) => void
  handleEdit: (value: any) => void
}

const initialOptions = {
  isOpen: false,
  errors: {}
}

const initialClient = {

}

const validate = (state: IClient) => {
  const errors: { [index: string]: string } = {}

  if (!state.name) errors.name = 'Name is required'
  if (!state.phoneNumber) errors.phoneNumber = 'Phone number is required'
  if (!state.email) errors.email = 'Email is required'
  if (!state.address?.address) errors.address = 'Address is required'

  if (state.phoneNumber && !isPossiblePhoneNumber(state.phoneNumber)) errors.phoneNumber = 'Invalid phone number'

  return errors
}

export function Clients () {
  const data: any = useLoaderData()
  const revalidator = useRevalidator()

  const clients = data?.clients

  const [client, reducer]: [IClient, React.Dispatch<IAction>] = useReducer(commonReducer, initialClient)
  const [options, optionsReducer] = useReducer(commonReducer, initialOptions)

  const navigate = useNavigate()

  const openPanel = useCallback(() => {
    optionsReducer({ path: 'isOpen', value: true })
  }, [])

  const dismissPanel = useCallback(() => {
    optionsReducer({ path: 'isOpen', value: false })
    reducer({ type: 'reset', value: initialClient })
  }, [])

  const dismiss = useCallback(() => {
    optionsReducer({ type: 'reset', value: initialOptions })
    reducer({ type: 'reset', value: initialClient })
  }, [])

  const handleClick = useCallback((row: APIClient) => {
    navigate(`${row.id}`)
  }, [navigate])

  const handleEdit = useCallback(() => {

  }, [])

  const handleSubmit = useCallback(async () => {
    const errors = validate(client)

    if (Object.values(errors).length) {
      return optionsReducer({ path: 'errors', value: errors })
    }

    try {
      const res = await api.query(CREATE_CLIENT, {
        args: client
      })

      const messages = res?.createClient?.messages as { message: string }[]
      const errors = res?.errors as undefined | string[]
      if (res?.createClient?.success) {
        revalidator.revalidate()
        dismiss()
      } else if (messages) {
        messages.map(({ message }) => Toast.makeText({ message, duration: 3000 }))
      } else if (errors) {
        errors.map(e => Toast.makeText({ message: e, duration: 3000 }))
      }
    } catch (e) {
      console.log(e)
    }
  }, [client, revalidator, dismiss])

  const onRenderFooterContent = useCallback(() => {
    return <Stack horizontal spacing={16} horizontalAlign='end'>
      <DefaultButton text='Cancel' onClick={dismissPanel} style={{ backgroundColor: 'white' }} />
      <PrimaryButton text='Submit' onClick={handleSubmit} />
    </Stack>
  }, [dismissPanel, handleSubmit])

  if (data.errors) return <NonFatalError errors={data.errors} />

  return <main>
    <Header>
      <h1 className='text-4xl text-center m-0'>Clients</h1>
      <PrimaryButton text='Add Client' onClick={openPanel} />
    </Header>
    {clients.length
      ? <PartnersTable data={clients} handleClick={handleClick} handleEdit={handleEdit} />
      : <EmptyState text='No Clients Yet' />}
    <Panel
      isLightDismiss
      isFooterAtBottom
      customWidth='500px'
      type={PanelType.customNear}
      isOpen={options.isOpen}
      onDismiss={dismissPanel}
      onRenderHeader={() => <h1 className='w-full ml-9'>Add Client</h1>}
      onRenderFooterContent={onRenderFooterContent}
    >
      <AddClientForm reducer={reducer} state={client} errors={options.errors} optionsReducer={optionsReducer} />
    </Panel>
  </main>
}

function PartnersTable ({ data, handleClick }: IPartnersTable) {
  return <Table id='partner-table' classNames={{ table: 'table' }}>
    <TableHeader>
      <HeaderCell>Name</HeaderCell>
      <HeaderCell>Address</HeaderCell>
      <HeaderCell>City</HeaderCell>
      <HeaderCell>State</HeaderCell>
      <HeaderCell>Phone Number</HeaderCell>
      <HeaderCell>Email</HeaderCell>
    </TableHeader>
    <Body>
      {data?.map((row, key) => <Row key={key}>
        <RowCell onClick={() => handleClick(row)}>{row.name}</RowCell>

        <RowCell>
          <a href={`https://www.google.com/maps/search/?api=1&query=${row.address.address.replace(' ', '%20')}%20${row.address.city}%2C%20${row.address.state}%20${row.address.zipcode}`} target='_blank' rel='noreferrer' className=''>
            {/* <LocationIcon color={theme.blue.hex()} size={20} /> */}
            {row.address.address}
          </a>
        </RowCell>
        <RowCell>
          {row.address.city}
        </RowCell>
        <RowCell>
          {row.address.state}
        </RowCell>
        <RowCell>
          <a href={`tel:${row.phoneNumber}`}>
            {/* <PhoneIcon color={theme.blue.hex()} size={18} /> */}
            {formatPhoneNumber(row.phoneNumber)}
          </a>
        </RowCell>
        <RowCell>
          <a href={`mailto:${row.email}`} target='_blank' rel='noreferrer'>
            {/* <EmailIcon color={theme.blue.hex()} size={18} /> */}
            {row.email}
          </a>
        </RowCell>
      </Row>
      )}
    </Body>
  </Table>
}
